import { react } from './react'

export type Question = {
  question: string
}

export type Topic = {
  topic: string
  totalQuestions: number
  questions: Question[]
}

export const quiz: Record<string, Topic> = {
  React: react,
}

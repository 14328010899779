import { FC } from 'react'
import styled from 'styled-components'

import { useQuiz } from '../../../context/QuizContext'
import { device } from '../../../styles/BreakPoints'
import { HighlightedText } from '../../../styles/Global'
import { convertSeconds } from '../../../utils/helpers'
import { Result } from '../../../types'

const ResultOverviewStyle = styled.div`
  text-align: center;
  margin-bottom: 70px;
  @media ${device.md} {
    margin-bottom: 30px;
  }
  p {
    margin-top: 15px;
    font-weight: 500;
    font-size: 18px;
  }
`

interface ResultOverviewProps {
  result: Result[]
}

const ResultOverview: FC<ResultOverviewProps> = ({ result }) => {
  const { quizDetails } = useQuiz()

  const totalQuestionAttempted = result.length



  return (
    <ResultOverviewStyle>
      <h3>Créditos</h3>
      <p>
        Este sitio web ha sido inspirado por <a href='https://www.5schnellefragen.de/'>5schnellefragen.de</a>, que a su vez está inspirado por <a href='https://open.spotify.com/show/7BTOsF2boKmlYr76BelijW'>el podcast Gemischtes Hack en alemán</a>.</p>
        <p>Te animamos encarecidamente a escuchar el podcast o a disfrutar de las preguntas en su versión original en dicho sitio web.</p>
      <p>Si quieres ayudarnos, puedes colaborar monetariamente invitándonos a un café en <a href='https://google.com'>Buy me a Coffee</a> o si quieres ayudar a mejorar el sitio web, no dudes en contactarme en <a href='mailto:nacho@nachologic.com'>mi correo electrónico</a>.</p>
      <p>Todos los créditos de este increíble sitio web van para <a href='https://github.com/AbdulBasit313/React-Quiz-App-Template'>AbdulBasit313</a> sin su código base esto no habría sido posible.</p>

    </ResultOverviewStyle>
  )
}

export default ResultOverview

// Question Types
// 1. MCQs | Multiple Choice | single
// 2. boolean | true/false | single
// 3. MAQs | Multiple Answers | multiple

import { Topic } from '.'

const questionsFromScript = [
        {
            "question": "\"Deber\u00edamos dejar de usar Instagram o Spotify por siempre?\""
        },
        {
            "question": "Crees que todo va a estar bien alg\u00fan d\u00eda?"
        },
        {
            "question": "\u00bfCu\u00e1l es tu nacionalidad de cocina favorita?"
        },
        {
            "question": "\u00bfCu\u00e1l es la tasa m\u00ednima de inter\u00e9s que te permite salir de la casa tranquilamente?"
        },
        {
            "question": "Encuentras piscinas p\u00fablicas tan desagradables como Tommi?"
        },
        {
            "question": "\u00bfQu\u00e9 fue la \u00faltima decisi\u00f3n que realmente arrepintiste?"
        },
        {
            "question": "Es el tema de los mercados de Navidad excitante o sobrevalorado?"
        },
        {
            "question": "Si fueras la persona m\u00e1s hermosa del mundo pero totalmente sin sentido de lo divertido, o la persona m\u00e1s divertida del mundo pero totalmente fea?"
        },
        {
            "question": "Preferir\u00eda vivir con tres estudiantes de primer semestre ambiciosos de Sociolog\u00eda o con noventa y nueve bulldogs locos."
        },
        {
            "question": "A veces te preguntes a qu\u00e9 te dedicas realmente en Alice Weidel."
        },
        {
            "question": "\"C\u00f3mo le parecer\u00eda un d\u00eda perfecto?\""
        },
        {
            "question": "\u00bfMe refer\u00eds a que hay alg\u00fan terrorista? (For formal Spanish) or \u00bfHay alg\u00fan terrorista? (For informal Spanish)"
        },
        {
            "question": "Si el Papa no fuera tan homof\u00f3bico, la Iglesia ser\u00eda un poco menos fea. \u00bfQu\u00e9 piensas de los perros en correas?"
        },
        {
            "question": "\u00bfCu\u00e1l es tu pa\u00eds favorito?"
        },
        {
            "question": "Ella habla ingl\u00e9s fluidamente. [She speaks English fluently.]"
        },
        {
            "question": "Debido a un peque\u00f1o error (2. Guerra Mundial), deber\u00eda cederle mi asiento en el tren a un anciano o mantenerme sentado?"
        },
        {
            "question": "\u00bfDebe dejar de beber cerveza por siempre o dejar de tomar caf\u00e9 por siempre?"
        },
        {
            "question": "Tomar\u00e1 este tiempo para obtener mi certificado de veh\u00edculo, mi licencia de conducir y mi oferta de electricidad."
        },
        {
            "question": "\"\u00bfC\u00f3mo te posas frente a una prohibici\u00f3n de globos?\""
        },
        {
            "question": "Preferis una Harley o una gran molinillo de pimienta en tu crisis de mediana edad?"
        },
        {
            "question": "\u00bfHas tomado alguna cosa de la carta de acciones en el restaurante algunas veces?"
        },
        {
            "question": "\u00bfQu\u00e9 de tu vida te hace sentir orgulloso en mayor medida?"
        },
        {
            "question": "\u00bfQu\u00e9 es lo m\u00e1s aburrido de ti?"
        },
        {
            "question": "\"C\u00f3mo bebes tu caf\u00e9?\""
        },
        {
            "question": "\u00bfC\u00f3mo se llama el nuevo ni\u00f1o en el jard\u00edn de infantes? [What is the name of the new child in kindergarten?]"
        },
        {
            "question": "Si fuera un vendedor de d\u00f6ner\u00eda y un cliente me molestaba, cortar\u00eda el pan de d\u00f6ner en trozos muy finos para que se robre al comerlo?"
        },
        {
            "question": "\"C\u00f3mo te rinas despus\u00e9 de lavar tus cabellos?\""
        },
        {
            "question": "Si miras diez a\u00f1os adelante, ves a ti mismo m\u00e1s en una casa rural o en un gran piso en la ciudad?"
        },
        {
            "question": "\"C\u00f3mo abordar a las mujeres hoy en d\u00eda sin que esto parezca raro?\""
        },
        {
            "question": "\u00bfQu\u00e9 piensas de las piernas de las mujeres peludas?"
        },
        {
            "question": "En tus a\u00f1os de adolescente, \u00bfcu\u00e1l era tu insulto preferido y qu\u00e9 es ahora?"
        },
        {
            "question": "Preferir\u00edas ir a una fiesta con un traje que no te gusta o con el traje de tu elecci\u00f3n, pero solo beber bebidas que no le gustan a la gente."
        },
        {
            "question": "Si encuentras a alguien mientras subes, con quien sabes bien para sentarnos juntos, pero no est\u00e1s en el humor para charlaras, \u00bfqu\u00e9 haces?"
        },
        {
            "question": "\u00bfCu\u00e1l era tu personaje de dibujos animados favorito cuando eras un ni\u00f1o?"
        },
        {
            "question": "\u00bfTe gusta m\u00e1s sentir estr\u00e9s en el punto de seguridad o en la caja? [You like it more to feel stressed at the security checkpoint or at the cashier's?]"
        },
        {
            "question": "\u00bfCu\u00e1l fue la \u00faltima peque\u00f1a cosa de la vida cotidiana que encontraste hermosa?"
        },
        {
            "question": "\u00bfCu\u00e1l de los botones o z\u00edperes para jeans te gusta m\u00e1s? [For buttons it would be \"botones\" and for zippers it would be \"z\u00edperes\"]"
        },
        {
            "question": "\"\u00bfC\u00f3mo te sentis sobre las c\u00e1maras Polaroid?\""
        },
        {
            "question": "En ese momento, \u00bfse hab\u00eda nuevamente abastecido de materiales escolares?"
        },
        {
            "question": "\u00bfCu\u00e1l es tu libro favorito? [What is your favorite book?]"
        },
        {
            "question": "\u00bfTe gusta terminar siempre cada comida de una sola vez?"
        },
        {
            "question": "\u00bfCU\u00e1l sonido odias? (capital C and U for \"CU\u00e1l\" to maintain the question's emphasis)"
        },
        {
            "question": "Habla aleman y ingl\u00e9s con fluidez."
        },
        {
            "question": "En qu\u00e9 historieta o serie desear\u00edas m\u00e1s que fuera realmente?"
        },
        {
            "question": "\u00bfCu\u00e1l programa de televisi\u00f3n te falta?"
        },
        {
            "question": "Tu perro duerme en tu cama? (Literally: \"Your dog sleeps in your bed?\")"
        },
        {
            "question": "En qu\u00e9 \u00e1rea crees tener la mayor falta de conocimientos?"
        },
        {
            "question": "\u00bfSentiste que alguien estaba sentado a tu lado en el tren o est\u00e1s dispuesto a recibir pasajeros sentados a tu lado?"
        },
        {
            "question": "En qu\u00e9 situaci\u00f3n te sientes mucho m\u00e1s atractivo o de moda que en realidad?"
        },
        {
            "question": "\u00bfCu\u00e1l fue la \u00faltima vez que te sentiste realmente mal de todos los modos?"
        },
        {
            "question": "Creo que lo mejor de crecer en una peque\u00f1a ciudad es el fuerte sentido de comunidad."
        },
        {
            "question": "Si fuera un actor, \u00bfpara qu\u00e9 papeles me adaptar\u00eda?"
        },
        {
            "question": "\u00bfC\u00f3mo te gustar\u00eda llamar a tu hija y c\u00f3mo a tu hijo? [Asking for the names separately]"
        },
        {
            "question": "\u00bfQu\u00e9 es la rumor m\u00e1s extra\u00f1a que me he escuchado sobre m\u00ed?"
        },
        {
            "question": "En qu\u00e9 tiempo y en qu\u00e9 lugar viajar\u00eda con una m\u00e1quina del tiempo?"
        },
        {
            "question": "\u00bfCu\u00e1l es tu salsa favorita para asar a la parrilla?"
        },
        {
            "question": "En una documental de naturaleza, un le\u00f3n caza a una ant\u00edlope. Para qui\u00e9n haces esta pregunta?"
        },
        {
            "question": "\u00bfTe pones antes de ducharte o vas primero al armario?"
        },
        {
            "question": "\u00bfEres un buen acompa\u00f1ante de vuelo? (For aviation) or \u00bfEres un buen acompa\u00f1ante en el coche? (For cars)"
        },
        {
            "question": "\u00bfQu\u00e9 fue tu soda favorita en la vida?"
        },
        {
            "question": "\u00bfTienes algo que tus padres siempre te hagan referencia cuando est\u00e1 en la televisi\u00f3n?"
        },
        {
            "question": "Permitirse ser reasentados en Marte?"
        },
        {
            "question": "A qui\u00e9nes generalmente conf\u00edo m\u00e1s: hombres o mujeres? (For formal situations)"
        },
        {
            "question": "En lo que hubieras podido ser muy bueno sin haberlo intentado antes."
        },
        {
            "question": "\u00bfHas recibido alguna vez un regalo que realmente te enoj\u00f3 mucho?"
        },
        {
            "question": "Crees que existe la belleza perfecta?"
        },
        {
            "question": "Con qu\u00e9 pol\u00edtico no te ir\u00edas de vacaciones?"
        },
        {
            "question": "En alto verano en la piscina al aire libre, el supervisor no est\u00e1 mirando ahora: c\u00f3mo te tus del borde?"
        },
        {
            "question": "Cu\u00e1l es en realidad demasiado barato para el servicio?"
        },
        {
            "question": "Desde que \u00faltima vez encontraste a un amigo? [Since what last time have you found a friend?]"
        },
        {
            "question": "\"Cu\u00e1ntas pantalones de gama corta tienes?\" (Literally: \"How many short-legged pants do you have?\") or \"Cu\u00e1ntos juegos de pantalones tienes?\" (Literally: \"How many sets of trousers do you have?\")"
        },
        {
            "question": "\"C\u00f3mo reacciono cuando el vendedor dice un precio alto?\""
        },
        {
            "question": "\u00bfQu\u00e9 fue tu \u00faltima compra en Amazon?"
        },
        {
            "question": "M\u00e1s de 2 millones de personas en Alemania no pueden leer ni escribir bien \u2013 \u00bfno sabes que si? \u00bfCu\u00e1ndo has corrido por una puerta \u00faltimamente?"
        },
        {
            "question": "Cu\u00e1ndo act\u00faas un poco infantil en la vida diaria?"
        },
        {
            "question": "\u00bfHas estado alguna vez atrapado en una ascensor?"
        },
        {
            "question": "\u00bfQu\u00e9 te construye socialmente como el m\u00e1s femenino o masculino a ti respecto?"
        },
        {
            "question": "\u00bfQu\u00e9 no sabes en realidad nada de ello?"
        },
        {
            "question": "\u00bfHas de vez en cuando dedicado tiempo a la melancol\u00eda? (Have you ever dedicated time to melancholy?)"
        },
        {
            "question": "\u00bfQu\u00e9 broma tuya m\u00e1s tonta has logrado realizar?"
        },
        {
            "question": "\u00bfCu\u00e1l es tu favorito, la sopa o la pulpa de mull? (Note: In Spanish, \"mull\" can be translated as \"sopa\" which means soup and \"pulpa\" which means porridge or gruel)"
        },
        {
            "question": "Con cu\u00e1l reality show famoso fallecido hubieses gustado hablar? [Con qui\u00e9n hubiese gustado hablar de un reality show famoso fallecido? - This is a more literal translation, but the given question uses \"mit\" which means \"with\" in this context.]"
        },
        {
            "question": "\"C\u00f3mo se presenta tu rutina de aburrimiento?\""
        },
        {
            "question": "[The sentence translated into Spanish]"
        },
        {
            "question": "\"C\u00f3mo calificas la expresi\u00f3n 'se puede lograr todo'?\""
        },
        {
            "question": "\u00bfHay verdad en los clich\u00e9s c\u00f3micos?"
        },
        {
            "question": "\u00bfCu\u00e1l bebida es buena para exactamente la duraci\u00f3n de una taza de ella?"
        },
        {
            "question": "Creo que el mundo est\u00e1 lleno de tales frases."
        },
        {
            "question": "\"\u00bfPueden ustedes darme instrucciones, peluquero/a?\""
        },
        {
            "question": "Preferir\u00edas comprar un deseo antes de trabajarlo?"
        },
        {
            "question": "Qui\u00e9n es una figura hist\u00f3rica con potencial de esc\u00e1ndalo tan grande como el de Shitstorm?"
        },
        {
            "question": "\u00bfQu\u00e9 comida estaba prohibida para ti cuando eras un ni\u00f1o?"
        },
        {
            "question": "\"Quieres ser algo, pero no es as\u00ed.\""
        },
        {
            "question": "Deber\u00eda introducirse una regla contra las objeciones para la donaci\u00f3n de \u00f3rganos?"
        },
        {
            "question": "Planes to llevarlo, pero no lo haces."
        },
        {
            "question": "Mi pel\u00edcula favorita de Disney es Alad\u00edn."
        },
        {
            "question": "\u00bfQu\u00e9 se faltaba m\u00e1s acerca del estudio?"
        },
        {
            "question": "Corregis mensajes de WhatsApp o prefieres escribir nuevos en su lugar?"
        },
        {
            "question": "\u00bfSon t\u00fa un durmidor o una persona que se levanta temprano?"
        },
        {
            "question": "\u00bfQu\u00e9 fue la decisi\u00f3n m\u00e1s dif\u00edcil que jam\u00e1s hubieses tenido que tomar?"
        },
        {
            "question": "Tu departamento tiene un obvio defecto? or Tu vivienda tiene un error aparente?"
        },
        {
            "question": "La casa es grande y hermosa: Tiene tres habitaciones."
        },
        {
            "question": "\u00bf Cu\u00e1les son los suegros peores: los d\u00farsconservadores de D\u00fcsseldorf o los divorciados que quieren ser especialmente a la moda?"
        },
        {
            "question": "\u00bfQu\u00e9 es lo m\u00e1s terror\u00edfico que ha ocurrido a ti?"
        },
        {
            "question": "\"C\u00f3mo te est\u00e1s manejando las consecuencias de la Corona?\""
        },
        {
            "question": "\u00bfCu\u00e1l fallo de crisis mediocana te suceder\u00eda?"
        },
        {
            "question": "Qu\u00e9 hubieses querido comprar, pero no lo hiciste en realidad?"
        },
        {
            "question": "Tienes preferencias especiales por documentales?"
        },
        {
            "question": "[The sentence translated into Spanish]"
        },
        {
            "question": "\u00bfC\u00f3mo te sientes en edad? (How do you feel in age?)"
        },
        {
            "question": "\u00bfNecesitas amor o eres el causante de la tensi\u00f3n cuando est\u00e1s ebrio?"
        },
        {
            "question": "Por qu\u00e9 deseas agradecerle a\u00fan este d\u00eda un consejo en particular?"
        },
        {
            "question": "Cuando andas en bicicleta, c\u00f3mo te detienes frente a un sem\u00e1foro? (When you cycle, how do you stop in front of a traffic light?)"
        },
        {
            "question": "\u00bfCu\u00e1les palabras est\u00e1s usando de forma incorrecta?"
        },
        {
            "question": "\u00bfQu\u00e9 esperanza has abandonado?"
        },
        {
            "question": "Quien le gustar\u00eda desplomar su \u00e9xito a alguien en la cara?"
        },
        {
            "question": "\u00bfHay una palabra de la escuela que se queda sentada? (Literally) / \u00bfQu\u00e9 palabra escolar est\u00e1 parada? (Figuratively)"
        },
        {
            "question": "\u00bfQu\u00e9 es la caracter\u00edstica m\u00e1s graciosa de tu hermano o hermana?"
        },
        {
            "question": "Guardas las llamadas m\u00f3viles almacenando: (You save mobile phone contacts by storing them)"
        },
        {
            "question": "\"C\u00f3mo te gustar\u00eda ser enterrado?\""
        },
        {
            "question": "No tiene traducci\u00f3n directa en ingl\u00e9s ni en espa\u00f1ol (There is no direct translation into English or Spanish)"
        },
        {
            "question": "\u00bfQu\u00e9 hora es para el pez Barbe? [Translation for \"Barb\" being a fish]"
        },
        {
            "question": "\u00a1Son t\u00fa un hincado de dedos? (Literally: \"Are you a finger-hinker?\") or \u00a1Son t\u00fa un quebrantahuesos? (Literally: \"Are you a bone-cracker?\"), depending on the intended meaning. The former is used when asking if someone can snap their fingers easily, while the latter might be used to ask if someone is a troublemaker or a gossip."
        },
        {
            "question": "\u00bfQu\u00e9 juguete preferes en los jardines de infantes?"
        },
        {
            "question": "En tu descanso de verano, \u00bfqu\u00e9 te dedicas? (En tu descanso de verano, \u00bfqu\u00e9 te dedicas t\u00fa?) for you specifically"
        },
        {
            "question": "\u00bfCu\u00e1l ser\u00eda tu motocicleta de preferencia?"
        },
        {
            "question": "\u00bfQu\u00e9 fue tu experiencia m\u00e9dica m\u00e1s extra\u00f1a o impresionante?"
        },
        {
            "question": "Cu\u00e1l app no utiliza pero no la elimina tampoco?"
        },
        {
            "question": "Por qu\u00e9 tema te dedicar\u00edas, si estuvieras econ\u00f3micamente seguro y hubieses resuelto todos los problemas mundiales graves?"
        },
        {
            "question": "\u00bfQu\u00e9 fue lo m\u00e1s ingenuo que jam\u00e1s hiciste?"
        },
        {
            "question": "\u00bfCu\u00e1l de las profesiones ser\u00eda peor para ser un compa\u00f1ero: Terapeuta de parejas o entrenador f\u00edsico?"
        },
        {
            "question": "Posadas t\u00fa este objeto espec\u00edfico dos veces?"
        },
        {
            "question": "Corregis peque\u00f1os errores ortogr\u00e1ficos al hablar por chat o simplemente los env\u00edas talqualquera?"
        },
        {
            "question": "Cu\u00e1l fallo te asegura de ocurrir alg\u00fan d\u00eda?"
        },
        {
            "question": "\u00bfQu\u00e9 esperas de reunirte con tus amigos?"
        },
        {
            "question": "\u00bfQu\u00e9 pueden ense\u00f1ar a los supermercados alemanes apariencias extranjeras contra las que se enfrentan? [Or, more colloquially:] \u00bfQu\u00e9 pueden aprender de los supermercados extranjeros que se les presentan? ["
        },
        {
            "question": "\u00bfQu\u00e9 fue tu \u00faltima experiencia que realmente cambi\u00f3 el curso de tu vida?"
        },
        {
            "question": "Preferis la versi\u00f3n peque\u00f1a, a pesar de que la mayor\u00eda prefiere la grande?"
        },
        {
            "question": "\"Si tendr\u00eda un jard\u00edn, c\u00f3mo lo dise\u00f1ar\u00eda?\""
        },
        {
            "question": "Est\u00e1s haciendo algo porque lo has aprendido mal sin saber si es cierto."
        },
        {
            "question": "\u00bfQu\u00e9 es tu entretenimiento preferido durante largas viajes en coche?"
        },
        {
            "question": "Un smoking es un abrigo para hombre con botones y sin mangas [A smoking jacket is a man's coat with buttons and no sleeves]"
        },
        {
            "question": "\u00bfTe pon\u00e9s objetivos?"
        },
        {
            "question": "Debes siempre dejar en orden cuando vienen visitas. (You always have to leave in order when guests come.)"
        },
        {
            "question": "\u00bfQu\u00e9 es tu actividad favorita en el parque?"
        },
        {
            "question": "Cu\u00e1ndo a tu te doy a grilar? (Literally \"When to you give yourself to grill?\")"
        },
        {
            "question": "\u00bfQu\u00e9 grabar\u00eda una c\u00e1mara oculta m\u00eda en la ascensor?"
        },
        {
            "question": "\u00bfTienes un lugar deseado en los EE.UU.?"
        },
        {
            "question": "\u00bfQu\u00e9 pr\u00e1cticas has realizado? or \u00bfQu\u00e9 experiencias has tenido?"
        },
        {
            "question": "\u00bfTambi\u00e9n tienes un lado extra\u00f1o o divertido?"
        },
        {
            "question": "Tuvi\u00e9ras rabia destructiva adolescente?"
        },
        {
            "question": "\u00bfQu\u00e9 fue una bifurcaci\u00f3n en tu destino?"
        },
        {
            "question": "De cu\u00e1l situaci\u00f3n de tu vida te gustar\u00eda tener un video?"
        },
        {
            "question": "\u00bfCu\u00e1l es tu decoraci\u00f3n preferida en tu sal\u00f3n?"
        },
        {
            "question": "\u00bfQu\u00e9 broma juvenil tuya tuvo consecuencias caras?"
        },
        {
            "question": "Donde tienes la mayor expectativa?"
        },
        {
            "question": "\"C\u00f3mo se ve tu descanso ideal en el \u00e1rea de descanso?\""
        },
        {
            "question": "Tiene miedo de ataques terroristas?"
        },
        {
            "question": "\u00bfQu\u00e9 recuerdo de la escuelita primaria te viene a la mente primero?"
        },
        {
            "question": "Escribes siempre a mano algo."
        },
        {
            "question": "Si tuviese que participar en Supertalent, har\u00eda lo siguiente:"
        },
        {
            "question": "\u00bfDe cu\u00e1las de tus caracter\u00edsticas les molesta a la gente?"
        },
        {
            "question": "Los zapatos c\u00f3modos y la ropa adecuada son esenciales para una manifestaci\u00f3n. Una m\u00e1scara o escarpe tambi\u00e9n puede ser importante."
        },
        {
            "question": "Si solo una temperatura pudiera gobernar desde ahora en adelante, cu\u00e1l ser\u00eda la que eligir\u00edas? [If only one temperature could rule from now on, which one would you choose?]"
        },
        {
            "question": "\u00bfQu\u00e9 tipo de herramienta de cocina soy?"
        },
        {
            "question": "[The sentence translated into Spanish]"
        },
        {
            "question": "[The sentence translated into Spanish]"
        },
        {
            "question": "\u00bfCu\u00e1l es la pieza de D\u00f6ner mejor?"
        },
        {
            "question": "\u00bfCu\u00e1l es el insulto infantil m\u00e1s cruel?"
        },
        {
            "question": "\u00bfHay pa\u00edses cuya imagen te impide tom\u00e1rselo en serio?"
        },
        {
            "question": "En cu\u00e1l masterclass te gustar\u00eda participar?"
        },
        {
            "question": "\u00bfCu\u00e1l es el mejor chiste en el formato de juego de \"Qui\u00e9n quiere ser millonario\"?"
        },
        {
            "question": "\u00bfQu\u00e9 realmente era mejor en el pasado? (What was really better in the past?)"
        },
        {
            "question": "\u00bfQu\u00e9 es una especia que siempre mejora el sabor de todo?"
        },
        {
            "question": "\u00bfQu\u00e9 preparas para la perfecta cena de promoci\u00f3n?"
        },
        {
            "question": "Tienes un estufa de mesa y la usas? (Literally: \"Do you have a tabletop stove and do you use it?\") or Tienes una batidora de mesa y la utilizas? (More common term for stand mixer in Spanish)"
        },
        {
            "question": "\u00bfQu\u00e9 fue lo m\u00e1s quesoso que jam\u00e1s hiciste antes?"
        },
        {
            "question": "\u00bfCu\u00e1l fue tu vacaciones peor?"
        },
        {
            "question": "\u00bfHas hecho alguna vez un amistad activamente? (Literally: \"Have you made an actively a friendship?\")"
        },
        {
            "question": "\u00bfQu\u00e9 es una equivocaci\u00f3n sobre mi personalidad?"
        },
        {
            "question": "\u00bfCu\u00e1l es el momento perfecto?"
        },
        {
            "question": "[The sentence translated into Spanish]"
        },
        {
            "question": "Alguien en tu c\u00edrculo de amigos se ha desarrollado de manera anticiclonal?"
        },
        {
            "question": "\u00bfQu\u00e9 art\u00edculo de series infantiles te gustar\u00eda tener?"
        },
        {
            "question": "[The sentence translated into Spanish]"
        },
        {
            "question": "En qu\u00e9 actividad te sientes m\u00e1s vivamente?"
        },
        {
            "question": "Cu\u00e1l deber es m\u00e1s satisfactorio?"
        },
        {
            "question": "\"C\u00f3mo se jugaba al f\u00fatbol callejero cuando eras ni\u00f1o/a?\""
        },
        {
            "question": "En qu\u00e9 tonter\u00edas te desmaya en tiendas de juguetes?"
        },
        {
            "question": "Lo que te viene a la mente primero: escuela primaria, instituto, universidad, trabajo."
        },
        {
            "question": "\u00bfCu\u00e1l es tu raza de perro favorita?"
        },
        {
            "question": "\u00bfQu\u00e9 problemas dejar\u00e1n de tener nuestros bisnietos?"
        },
        {
            "question": "\u00bfQu\u00e9 fue tu \u00faltima compra significativa o razonable?"
        },
        {
            "question": "\u00bfQu\u00e9 tuvo un gran impacto en tu vida mirando atr\u00e1s?"
        },
        {
            "question": "\u00bfQu\u00e9 fue lo mejor de la \u00e9poca de la corona de COVID-19?"
        },
        {
            "question": "\u00bfQu\u00e9 es la mejor recomendaci\u00f3n masculina de tu padre?"
        },
        {
            "question": "Qu\u00e9 es una cadena rentable en la que nunca compras?"
        },
        {
            "question": "\u00bfQu\u00e9 piensas de un Budhismo le\u00eddo en falso?"
        },
        {
            "question": "\u00bfQu\u00e9 es la posici\u00f3n de la letra sorprendente en el alfabeto?"
        },
        {
            "question": "Cu\u00e1l cami\u00f3n comercial te gustar\u00eda manejar alg\u00fan tiempo?"
        },
        {
            "question": "Por qu\u00e9 no hay peces payaso con un autom\u00e1tico desplegable hasta ahora? (Why don't clownfish have a drop-down automatic yet? - for the inverse question)"
        },
        {
            "question": "\u00bfQu\u00e9 caracter\u00edstica tuya ha sufrido un cambio fundamental?"
        },
        {
            "question": "En qu\u00e9 lugar podr\u00edas mirar a alguien para siempre?"
        },
        {
            "question": "\u00bfCu\u00e1l es tu opini\u00f3n sobre la legalizaci\u00f3n de la marihuana?"
        },
        {
            "question": "\u00bfQu\u00e9 riesgo empresarial estar\u00eda dispuesto a aceptar?"
        },
        {
            "question": "Tiene miedo irracional de prohibiciones que no existen? (Por ejemplo, encender la luz en un coche)"
        },
        {
            "question": "En qu\u00e9 asunto tienes comprensi\u00f3n pero sigues siendo una persona mal educada?"
        },
        {
            "question": "\u00bfQu\u00e9 juego de fantas\u00eda era tu favorito cuando eras un ni\u00f1o?"
        },
        {
            "question": "En qu\u00e9 edad te sientes m\u00e1s alegre/de lo m\u00e1s relajado? (In what age do you feel happiest/most relaxed?)"
        },
        {
            "question": "\u00bfEn qu\u00e9 direcci\u00f3n dejar\u00eda Alemania si pudiese?"
        },
        {
            "question": "Con qu\u00e9 caldera de problemas tuviste suerte hasta ahora?"
        },
        {
            "question": "\u00bfQu\u00e9 haces, a pesar de que te hace nerviosos, cuando otras personas lo hacen alrededor tuyo o en tu entorno?"
        },
        {
            "question": "\u00bfTe sientes relacionado con una persona famosa en un modo familiar?"
        },
        {
            "question": "Cu\u00e1l olor te hace recordar a tu hogar?"
        },
        {
            "question": "\u00bfQu\u00e9 hace que otros est\u00e9n felices, pero ninguno otro lo logra con la misma cosa?"
        },
        {
            "question": "Es necesario que compres una casa de descanso para ti: monta\u00f1as o playa? [It's necessary that you buy a vacation house for yourself: mountains or beach?]"
        },
        {
            "question": "Seg\u00fan tu punt de vista, \u00bfcu\u00e1nto puede durar un r\u00e9gimen criminal antes de recibir nombres refinados?"
        },
        {
            "question": "\u00bfQu\u00e9 finds muy conveniente de ti en un lugar que en realidad no lo est\u00e1 dise\u00f1ado para eso?"
        },
        {
            "question": "Qu\u00e9 cosa, bajo la cual pasan muchas personas, suertemente no la sufres t\u00fa?"
        },
        {
            "question": "Con lo que realmente nadie puede hacerle gozo."
        },
        {
            "question": "\u00bfHay alg\u00fan lugar en el que no tienes miedo del crimen lo suficiente, a pesar de que ser\u00eda adecuado?"
        },
        {
            "question": "\u00bfCu\u00e1l es tu favorito atracci\u00f3n de parque tem\u00e1tico?"
        },
        {
            "question": "\u00bfCu\u00e1l actividad, que hago con una mano fuerte, la hago peor con la otra?"
        },
        {
            "question": "Te gusta planear? [Te gusta is \"you like\" in Spanish]"
        },
        {
            "question": "\u00bfQu\u00e9 es tu tema favorito sobre Smalltalk?"
        },
        {
            "question": "En qu\u00e9 momentos conduces como una abuela t\u00edpica?"
        },
        {
            "question": "\u00bfCuidas mucho de lavarte los pies durante la ducha?"
        },
        {
            "question": "\u00bfQu\u00e9 te diferencia en el sector de la gastronom\u00eda?"
        },
        {
            "question": "\u00bfD\u00f3nde te vio \u00faltimamente?"
        },
        {
            "question": "Sobre cu\u00e1l cosa pod\u00edas hacer antes, pero ahora ya no puedes, est\u00e1s especialmente triste?"
        },
        {
            "question": "Cu\u00e1l figura de lenguaje o locuci\u00f3n siempre es cierta?"
        },
        {
            "question": "Seg\u00fan tu punt de vista, cu\u00e1nto tiempo crees que le tomara a la corona entrar en la cultura popular?"
        },
        {
            "question": "\u00bfQu\u00e9 cosa retirada del mercado te gustar\u00eda tener de regreso?"
        },
        {
            "question": "En qu\u00e9 situaciones despliegas una forma patri\u00f3tica y c\u00f3mica?"
        },
        {
            "question": "Cu\u00e1l especialidad m\u00e9dica es la m\u00e1s atractiva?"
        },
        {
            "question": "Si fuera posible para usted elegir a cualquier persona para representarlo en su cumplea\u00f1os, a qui\u00e9n le hubiese otorgado este cargo de honor?"
        },
        {
            "question": "Usas frecuentemente una palabra, pero la has desfigurado gravemente?"
        },
        {
            "question": "Hay alguien cuyo output disfrutas, pero no te gusta en absoluto?"
        },
        {
            "question": "\u00bfQu\u00e9 rasgo f\u00edsico de la edad te encontras particularmente gracioso sobre ti?"
        },
        {
            "question": "Si pudiera gastarme 7000 euros a m\u00ed, \u00bfqu\u00e9 comprar\u00eda con ellas?"
        },
        {
            "question": "\u00bfQu\u00e9 cosa peque\u00f1a hace que tu vida sea mucho mejor (o mucho peor, si no la tienes)?"
        },
        {
            "question": "Empiezas de nuevo y de nuevo en algo, aunque sabes que pronto debes detenerte."
        },
        {
            "question": "Hablo fluidamente alem\u00e1n, ingl\u00e9s y espa\u00f1ol. (I speak fluently German, English and Spanish.)"
        },
        {
            "question": "Cu\u00e1l comida es divertida hasta cierto punto, pero demasiado de ella se vuelve realmente desagradable con un golpeteo?"
        },
        {
            "question": "\u00bfQu\u00e9 recomendaci\u00f3n har\u00edas a un ni\u00f1o millonario?"
        },
        {
            "question": "\u00bfEn qu\u00e9 destino de vacaciones te gustar\u00eda ir este a\u00f1o?"
        },
        {
            "question": "\u00bfQu\u00e9 es peor: alguien cuya ropa tiene un mal olor o alguien cuya ropa tiene un fuerte olor a detergente y suavizante?"
        },
        {
            "question": "Por cu\u00e1l argumento de defensa por homicidio me estoy lentamente identificando y comprenediendo?"
        },
        {
            "question": "Cu\u00e1l de tus caracter\u00edsticas ha sufrido una transformaci\u00f3n completa a lo largo de tu vida?"
        },
        {
            "question": "Preferir\u00edas dejar de montar en bicicleta por siempre para estar en perfecta salud, o continuar\u00eda haci\u00e9ndolo pero tener alergias?"
        },
        {
            "question": "Cu\u00e1l ser\u00eda usted el protagonista de una pel\u00edcula de acci\u00f3n: el h\u00e9roe, el nerd, el bulldozer o la agente del servicio secreto enemigo?"
        },
        {
            "question": "Escuchas la expresi\u00f3n \"videoclip de m\u00fasica\". \u00bfQu\u00e9 te imaginas?"
        },
        {
            "question": "Tienes 9 minutos para comerte adecuadamente, pues luego no lo podr\u00e1s hacer: \u00bfQu\u00e9 vas a comer?"
        },
        {
            "question": "\u00bfCu\u00e1l es tu papel preferido durante una noche de asado? (Anfitri\u00f3n, Parrillero, Traedor-algo, ...)"
        },
        {
            "question": "\u00bfQu\u00e9 delito criminal cometer\u00edas, si fuera necesario obtener dinero de pronto? (What crime would you commit, if it were necessary to obtain money urgently?)"
        },
        {
            "question": "Si fuera mecenas en mi ciudad, har\u00eda..."
        },
        {
            "question": "\u00bfCu\u00e1l es el nivel de clase peor para un maestro?"
        },
        {
            "question": "\"Hagamoslo honesto ahora: \u00bfQu\u00e9 fue realmente mejor antes?\""
        },
        {
            "question": "\u00bfHay algo en tu comportamiento que sugiera que no te sientes bien en este momento?"
        },
        {
            "question": "\u00bfQu\u00e9 te sientes de manera desproporcionadamente intensa, a pesar de que en realidad no te importa mucho?"
        },
        {
            "question": "Si fuera la persona m\u00e1s rica del mundo, c\u00f3mo me vestir\u00eda?"
        },
        {
            "question": "\u00bfC\u00f3mo te sientes normalmente?"
        },
        {
            "question": "\u00bfQu\u00e9 hace el ruido m\u00e1s fuerte en tu departamento que puede caerte encima? [qu\u00e9 hace el ruido m\u00e1s fuerte en tu departamento que pudiese caerte encima?]"
        },
        {
            "question": "\u00bfQu\u00e9 fue la \u00faltima cosa que siempre te alejaste, pero finalmente la has logrado?"
        },
        {
            "question": "\u00bfQu\u00e9 hay contigo en tu departamento ahora mismo?"
        },
        {
            "question": "Te sacaron mal?"
        },
        {
            "question": "Tuvas tenido un accidente de coche some times? (Literally: \"You have had a car accident some times?\")"
        },
        {
            "question": "\u00bfC\u00f3mo pasar\u00edas tu tiempo en el \u00faltimo d\u00eda antes del fin del mundo?"
        },
        {
            "question": "Cu\u00e1l fue la asociaci\u00f3n alimentaria m\u00e1s exitosa y cu\u00e1l la menos exitosa?"
        },
        {
            "question": "Hay algo en la cocina donde no est\u00e1s muy cuidadoso en lo que respecta a la higiene? (Literally: \"Is there something in the kitchen where you are not very careful about hygiene matters?\")"
        },
        {
            "question": "\u00bfCu\u00e1l canci\u00f3n tiene un buen *refr\u00e1n*, pero lo dem\u00e1s no es muy bien?"
        },
        {
            "question": "\u00bfCu\u00e1l emoci\u00f3n debe ser visible directamente para otros?"
        },
        {
            "question": "\u00bfHas ganado alguna vez antes?"
        },
        {
            "question": "\u00bfQu\u00e9 fue tu primera desilusi\u00f3n pol\u00edtica?"
        },
        {
            "question": "En qu\u00e9 momento has crecido hasta convertirte en un adulto?"
        },
        {
            "question": "T\u00fa eres muy h\u00e1bil en alem\u00e1n y ingl\u00e9s. [English: You are very skilled in German and English.]"
        },
        {
            "question": "\u00bfQu\u00e9 fue la \u00faltima cosa extra\u00f1a que viste en Internet?"
        },
        {
            "question": "\u00bfQu\u00e9 hobby te dedicar\u00e1s en el jubilado?"
        },
        {
            "question": "\u00bfQu\u00e9 ser\u00eda la primera cosa que mi yo de seis a\u00f1os har\u00eda si estuviera en tu cuerpo ahora?"
        },
        {
            "question": "\u00bfQu\u00e9 es tu plato turco favorito adem\u00e1s de D\u00f6ner?"
        },
        {
            "question": "Lo que necesita ser inventado inmediatamente."
        },
        {
            "question": "En \"Ciudad, Pa\u00eds, R\u00edo,\" el drag\u00f3n es un animal?"
        },
        {
            "question": "\u00bfCu\u00e1l fue la \u00faltima vez en la que te molestaste a ti mismo?"
        },
        {
            "question": "Hay un producto que me hace preguntar por qu\u00e9 no lo compro en cantidades grandes inmediatas."
        },
        {
            "question": "\u00bfQu\u00e9 es la comida menos satisfactoria cuando est\u00e1s realmente hambriento?"
        },
        {
            "question": "Por qu\u00e9 estoy agradecido que haya correcci\u00f3n ortogr\u00e1fica en este t\u00e9rmino? (Literally: Why am I grateful that there is orthographic correction in this term?)"
        },
        {
            "question": "\u00bfCu\u00e1l fue la \u00faltima vez que desviastete de tu rutina diaria completa?"
        },
        {
            "question": "\u00bfCu\u00e1ntas veces se puede preguntar cuando algo no queda claro?"
        },
        {
            "question": "\u00bfQu\u00e9 es lo m\u00e1s rid\u00edculo para pedir a comer?"
        },
        {
            "question": "\u00bfQu\u00e9 deseas comprar para ti a continuaci\u00f3n?"
        },
        {
            "question": "En qu\u00e9 evento de mamadas querr\u00edas asistir?"
        },
        {
            "question": "Encuentras tambi\u00e9n que la correcci\u00f3n ortogr\u00e1fica ha vuelto demasiado intrusiva?"
        },
        {
            "question": "En qu\u00e9 tiempo se califica una persona como conductor para totar? (Literally: \"In what time does one qualify as a driver to honk?\")"
        },
        {
            "question": "\u00bfCu\u00e1l funci\u00f3n corporal consideras por completo innecesaria?"
        },
        {
            "question": "\u00bfQu\u00e9 hac\u00edas de verg\u00fcenza de hacerlo demasiado poco?"
        },
        {
            "question": "\u00bfEn qu\u00e9 orden se deben hacer las cosas al volver a casa?"
        },
        {
            "question": "\u00bfCu\u00e1l programa de televisi\u00f3n le gustar\u00eda presentar inmediatamente?"
        },
        {
            "question": "\u00bfQu\u00e9 alimento es el m\u00e1s innecesario?"
        },
        {
            "question": "\u00bfQu\u00e9 poseo y uso, a pesar de ser considerado demasiado viejo para ello?"
        },
        {
            "question": "Entend\u00e9s la locura m\u00e1s all\u00e1 de la muerte?"
        },
        {
            "question": "\u00bfQu\u00e9 har\u00edas para salvar una cosa de tu casa en llamas si solo pudieras salvar una cosa? [What would you do to save a thing from your house on fire if you could only save one thing?]"
        },
        {
            "question": "\u00bfQu\u00e9 podr\u00eda ser la m\u00e1s aburrida de comienzos para m\u00ed en una noche de fiesta?"
        },
        {
            "question": "\u00bfCu\u00e1l ser\u00eda la pregunta que me haga mi yo de quince a\u00f1os?"
        },
        {
            "question": "\u00bfQu\u00e9 fue tu momento m\u00e1s destacado este a\u00f1o pasado?"
        },
        {
            "question": "Le explicar\u00eda a un ni\u00f1o de cinco a\u00f1os llamado Leo que ayudo a las personas a entender cosas que no saben albuscando informaci\u00f3n y explic\u00e1ndoselo."
        },
        {
            "question": "\u00bfHubiese sido Wettking Samuel Koch si no hubiese ca\u00eddo?"
        },
        {
            "question": "\u00bfCu\u00e1l de estos dos platos, la Pizzabaguette o el Rostbratwurst, has comido quemado m\u00e1s a menudo tu boca superior?"
        },
        {
            "question": "Preferir\u00edas cuidar de un tigre adulto durante dos semanas o de un gallo durante diez a\u00f1os?"
        },
        {
            "question": "\u00bfHas empezado a beber y ya tienes miedos para el d\u00eda siguiente?"
        },
        {
            "question": "\u00bfQu\u00e9 debemos hacer con el papel de envasar: doblarlo, amasarlo o enrollarlo?"
        },
        {
            "question": "Si fuera una cerveza, ser\u00eda una cierta cerveza y la raz\u00f3n ser\u00eda... (Same as English, the translation depends on the answer.)"
        },
        {
            "question": "\"C\u00f3mo triste te encontras con este citado de Paulo Coelho: 'Si deseas realmente algo desde el fondo de tu coraz\u00f3n, entonces est\u00e1s m\u00e1s cerca del alma del mundo, ella siempre es buena.' \""
        },
        {
            "question": "\u00bfQu\u00e9 son tres cosas peores que mudarse?"
        },
        {
            "question": "Un par de amigos se discuten sobre el dinero a tu presencia, o tus amigos te muestran un video borracho de ti el d\u00eda despu\u00e9s de una ca\u00edda total. \u00bfQu\u00e9 es m\u00e1s desagradable?"
        },
        {
            "question": "\u00bfEres temerario? (Are you daring?) or \u00bfEres ingenioso? (Are you clever or witty?) depending on the context. In this case, \"mischievous\" would be a good translation for \"kitzlig,\" but it's worth noting that there are subtleties to both words and context is important when translating between languages."
        },
        {
            "question": "Est\u00e1 en realidad sobre la chocolate amargo dulce."
        },
        {
            "question": "[The sentence translated into Spanish]"
        },
        {
            "question": "Hace feliz ganar dinero?"
        },
        {
            "question": "\"C\u00f3mo te presenta tu rutina matutina?\""
        },
        {
            "question": "\u00bfC\u00f3mo se presenta un d\u00eda laboral normal para ti?"
        },
        {
            "question": "Ganas mucho en tu trabajo? (Literally: \"Earn a lot in your work?\")"
        },
        {
            "question": "\u00bfPreferes las u\u00f1as de las mujeres manicuradas o naturales?"
        },
        {
            "question": "Preferir\u00edas un crucero por el Mediterr\u00e1neo con Aida o una vacaci\u00f3n todo incluida en Lanzarote?"
        },
        {
            "question": "D\u00f6ner o D\u00fcr\u00fcm? (D\u00f6ner or D\u00fcr\u00fcm?)"
        },
        {
            "question": "Debo limpiar la cocina o el ba\u00f1o?"
        },
        {
            "question": "\"\u00bfQu\u00e9 es, Kicker o billar (o pool)?\""
        },
        {
            "question": "Te gustar\u00eda ser amigos?"
        },
        {
            "question": "Est\u00e1s recolectando algo?"
        },
        {
            "question": "\u00bfQu\u00e9 fue tu \u00faltima buena acci\u00f3n reciente?"
        },
        {
            "question": "Cu\u00e1l es tu cadena de supermercados preferida?"
        },
        {
            "question": "Crees que hacerlo diferencia llevar animales en botes de refugiados?"
        },
        {
            "question": "\u00bfPensas que los golondrinas se llaman as\u00ed porque se encuentran com\u00fanmente durante un paseo?"
        },
        {
            "question": "Hablas muy bien alem\u00e1n. [You speak very well German.]"
        },
        {
            "question": "\u00bfCu\u00e1l es tu anim\u00e1l preferido? [What is your preferred animal?]"
        },
        {
            "question": "Despaches esa cantidad de dinero de la m\u00e1quina recargable cada vez?"
        },
        {
            "question": "\u00bfHas ido all\u00ed recientemente? (recent past)"
        },
        {
            "question": "Por cu\u00e1nto dinero vendr\u00eda tu voz pol\u00edtica?"
        },
        {
            "question": "\u00bfC\u00f3mo te gustar\u00eda llamar a tu primog\u00e9nito?"
        },
        {
            "question": "\u00bfCu\u00e1ntos bicicletas han sido robadas a usted hasta ahora?"
        },
        {
            "question": "\u00bfCu\u00e1l tipo de tostada eres?"
        },
        {
            "question": "Ganar la loter\u00eda y no hacer nada al respecto ser\u00eda tu cosa?"
        },
        {
            "question": "En qu\u00e9 programa de televisi\u00f3n te gustar\u00eda participar alg\u00fan d\u00eda?"
        },
        {
            "question": "\u00bfPlanes tener ni\u00f1os en los pr\u00f3ximos cinco a\u00f1os?"
        },
        {
            "question": "\u00bfDebe dejar de beber t\u00e9 por siempre o dejar de beber caf\u00e9 por siempre?"
        },
        {
            "question": "Por qu\u00e9 la gente se convierte en pol\u00edticos? [Por qu\u00e9 las personas se convierten en pol\u00edticos? in formal register]"
        },
        {
            "question": "\u00bfHas entendido tama\u00f1os BH?"
        },
        {
            "question": "Hablo tres idiomas: ingl\u00e9s, alem\u00e1n y espa\u00f1ol."
        },
        {
            "question": "\"C\u00f3mo celebras Navidad?\""
        },
        {
            "question": "\u00bfVas a poner primero las calzas o los pantalones? [Which comes first, putting on the socks or the pants?]"
        },
        {
            "question": "\u00bfHas visto un asesinato horrible?"
        },
        {
            "question": "\u00bfPreferis ciertos tipos de salchichas?"
        },
        {
            "question": "\"Manzanas con mayonesa, ketsup o ambas?\""
        },
        {
            "question": "\u00bfCu\u00e1l fue la \u00faltima vez que te sentiste tonto?"
        },
        {
            "question": "\u00bfQu\u00e9 drogas has probado antes?"
        },
        {
            "question": "\u00bfQu\u00e9 deseas t\u00fa mismo para esta Navidad?"
        },
        {
            "question": "Lo har\u00eda para poner fin a la hambre en el mundo."
        },
        {
            "question": "\"C\u00f3mo conduces un autom\u00f3vil (tomar presi\u00f3n sobre la rueda de direcci\u00f3n)?\""
        },
        {
            "question": "\u00bfTienes una costumbre graciosa?"
        },
        {
            "question": "Los contrabandistas no ponen sellos en oro."
        },
        {
            "question": "\u00bfPreferir\u00edas ser etiquetado racista o sexista?"
        },
        {
            "question": "\u00bfSupones que Hitler y Furzen se cansar\u00e1n alg\u00fan d\u00eda?"
        },
        {
            "question": "\"\u00bfQu\u00e9 piensas de los deportes extremos?'\""
        },
        {
            "question": "[The sentence translated into Spanish]"
        },
        {
            "question": "Es Stromberg o Pastewka?"
        },
        {
            "question": "Estuvas pasando un domingo ocioso en la cama o en el sof\u00e1?"
        },
        {
            "question": "\"C\u00f3mo reacciono cuando mis vecinos tocan m\u00fasica demasiadamente loud?\""
        },
        {
            "question": "\u00bfQuieres saber tu d\u00eda de la muerte?"
        },
        {
            "question": "\u00bfComer\u00edas insectos?"
        },
        {
            "question": "\u00bfQu\u00e9 haces cuando est\u00e1s triste de amor?"
        },
        {
            "question": "\u00bfQu\u00e9 es tu forma preferida de retrasarlo?"
        },
        {
            "question": "[The sentence translated into Spanish]"
        },
        {
            "question": "\u00bfQu\u00e9 es un producto relativamente caro para ti que lo has pensado mucho?"
        },
        {
            "question": "\u00bfC\u00f3mo podr\u00eda cometer el asesinato perfecto?"
        },
        {
            "question": "Cu\u00e1l celebridad encuentras cool de manera secreta?"
        },
        {
            "question": "\"C\u00f3mo las encuentras los lugares de inter\u00e9s?\" or \" \u00bfD\u00f3nde las encuentras las atracciones?\""
        },
        {
            "question": "Cuando vas al ba\u00f1o, \u00bfqu\u00e9 te ocupas en lugar de tener tu tel\u00e9fono consigo?"
        },
        {
            "question": "\u00bfCu\u00e1l t\u00edtulo de pel\u00edcula describir\u00eda mejor mi vida actual?"
        },
        {
            "question": "El ni\u00f1o juega con la pelota."
        },
        {
            "question": "[The sentence translated into Spanish]"
        },
        {
            "question": "Si Jes\u00fas hubiese muerto de otra forma, crees que la iglesia tendr\u00eda otro s\u00edmbolo?"
        },
        {
            "question": "Te gusta el queso y el pan, pero no las tomates."
        },
        {
            "question": "\u00bfCu\u00e1l canci\u00f3n no escuchas ni una vez ni quince veces de seguida?"
        },
        {
            "question": "\u00bfTienes una pel\u00edcula que disfrutas a pesar de sentirte culpable por ello?"
        },
        {
            "question": "Por qu\u00e9 tienes miedo? (Why do you have fear?)"
        },
        {
            "question": "\"En qu\u00e9 sentido entendes el t\u00e9rmino 'prominencia' hoy en d\u00eda?\""
        },
        {
            "question": "Con cu\u00e1l famoso querr\u00edas cambiar de lugar por un d\u00eda?"
        },
        {
            "question": "Cu\u00e1l era tu d\u00eda cuando estuvisteis enfermos en casa antes durante la escuela?"
        },
        {
            "question": "\u00bfQui\u00e9n crees, teniendo en cuenta lo objetivo, que es la persona m\u00e1s feliz del mundo?"
        },
        {
            "question": "\"En una noche de fiesta, cu\u00e1nto dinero llevas consigo?\""
        },
        {
            "question": "Tienes un pulgar verde?"
        },
        {
            "question": "Hiciste bien las preparaciones para una prueba anterior, siendo meticuloso? (For Spanish, we use \"meticuloso\" instead of \"diligent\" as a direct translation)"
        },
        {
            "question": "\u00bfCu\u00e1l mascota tengo dificultades para entenderlo?"
        },
        {
            "question": "\"\u00bfC\u00f3mo te sent\u00eds acerca de la idea de una sauna?\""
        },
        {
            "question": "\"\u00bfCu\u00e1nto soy de calor en una escala de 1 a 10?\""
        },
        {
            "question": "Todas las mujeres est\u00e1n enamoradas de tazas. Por qu\u00e9 es as\u00ed?"
        },
        {
            "question": "\u00bfQu\u00e9 m\u00e1s tranquiliza a usted?"
        },
        {
            "question": "En qu\u00e9 momento hist\u00f3rico hubieses querido estar presente?"
        },
        {
            "question": "Crees que est\u00e1s adicto a las redes sociales?"
        },
        {
            "question": "Deb\u00e9is decider si elegir el equipo de los Frosties o Smacks."
        },
        {
            "question": "Cu\u00e1l es el comportamiento t\u00edpicamente masculino/femenino que muestras hoy?"
        },
        {
            "question": "\u00bfQu\u00e9 h\u00e1bito te gustar\u00eda dejar atr\u00e1s?"
        },
        {
            "question": "Hubieras resistido en el Tercer Reich? (Hubieras means \"you would have\" and resistido is \"resisted\")"
        },
        {
            "question": "\u00bfTe gusta la mesa tenis?"
        },
        {
            "question": "\u00bfHay algo en lo que creas supersticioso?"
        },
        {
            "question": "\u00bfCu\u00e1l plan de entrenamiento est\u00e1s siguiendo en el gimnasio actualmente?"
        },
        {
            "question": "A qui\u00e9n debes disculparte realmente?"
        },
        {
            "question": "\u00bfCu\u00e1l deporte lo peores t\u00fa?"
        },
        {
            "question": "\u00bfCu\u00e1l canci\u00f3n me hace sentir melanc\u00f3lico cada vez?"
        },
        {
            "question": "\u00bfCu\u00e1ntos \u00e1rboles puedes nombrar cuando los veas?"
        },
        {
            "question": "\u00bfCu\u00e1ntas veces vas al peluquero?"
        },
        {
            "question": "\"\u00bfC\u00f3mo te sentis sobre el f\u00fatbol femenino?\""
        },
        {
            "question": "\u00bfQu\u00e9 har\u00edas en tu crisis de mediana edad (en un restaurante, un bar, etc.)?"
        },
        {
            "question": "Eras una persona cool pero inusual?"
        },
        {
            "question": "\"Brasas o pantalones de correr?\" (Calves or running pants?)"
        },
        {
            "question": "\u00bfPuedes comer con palitos?"
        },
        {
            "question": "\"C\u00f3mo te presenta el verano?\""
        },
        {
            "question": "\u00bfQu\u00e9 es la m\u00e1s agradable complimiento que alguien puede hacerme?"
        },
        {
            "question": "En qu\u00e9 g\u00e9nero musical te sales del club inmediatamente?"
        },
        {
            "question": "Sabes el par que se hacen malas caras entre s\u00ed en presencia de otros?"
        },
        {
            "question": "\u00bfCu\u00e1l fue tu n\u00famero de tel\u00e9fono de la infancia?"
        },
        {
            "question": "\u00bfCu\u00e1l fue la \u00faltima vez que te comportaste correctamente?"
        },
        {
            "question": "\u00bfTe quejas de alguien si no se lava las manos en un ba\u00f1o p\u00fablico?"
        },
        {
            "question": "Dejas de usar WhatsApp contigo. (You leave using WhatsApp with me.)"
        },
        {
            "question": "\u00bfQu\u00e9 anuncio antiguo a\u00fan tienes en mente?"
        },
        {
            "question": "Qu\u00e9 te ha embarasado recientemente?"
        },
        {
            "question": "\u00bfTe gusta comer solo?"
        },
        {
            "question": "\u00bfCu\u00e1l fue la viaje m\u00e1s largo que has hecho alguna vez en autob\u00fas?"
        },
        {
            "question": "\u00bfQu\u00e9 es la broma paternal m\u00e1s t\u00edpica que tu padre nunca hizo antes?"
        },
        {
            "question": "\u00bfCu\u00e1l palabra de modo te resulta peor?"
        },
        {
            "question": "Qui\u00e9n es un celebridad aburrido?"
        },
        {
            "question": "\u00bfC\u00f3mo morir de manera m\u00e1s temible?"
        },
        {
            "question": "\u00bfEn qu\u00e9 lugar tienes tu wrinkle m\u00e1s profundo?"
        },
        {
            "question": "[The sentence translated into Spanish]"
        },
        {
            "question": "\"C\u00f3mo respondes a la telef\u00f3nada entre amigos?\""
        },
        {
            "question": "A cu\u00e1l concierto te gustar\u00eda asistir?"
        },
        {
            "question": "[The sentence translated into Spanish]"
        },
        {
            "question": "[The sentence translated into Spanish]"
        },
        {
            "question": "\"De Tuts y Chirrios\" (Literally: Of Tuts and Whistles) or \"De Tutos y de Todos lo Que Hace Ruido\" (More figuratively: \"Of All Kinds of Noise Makers\") depending on the context."
        },
        {
            "question": "\u00bfQu\u00e9 te descomfi\u00f3 en tu trabajo?"
        },
        {
            "question": "No, no participar\u00eda en un programa de televisi\u00f3n. (Si la pregunta es negativa)"
        },
        {
            "question": "\u00bfHas estado a punto de morir alguna vez?"
        },
        {
            "question": "\u00bfHay algo que actualmente te pasa desapercibido por ser una tendencia?"
        },
        {
            "question": "\u00bfNo eres muy bueno/a? (For masculine and feminine subjects, respectively)"
        },
        {
            "question": "\"Voy a usar gafas o lentes de contacto?\""
        },
        {
            "question": "\u00bfTienes un certificado de nataci\u00f3n?"
        },
        {
            "question": "\"Cu\u00e1nto pesas?\""
        },
        {
            "question": "\"Cu\u00e1nto cuesta el libro?\""
        },
        {
            "question": "\u00bfTienes un plato favorito raro?"
        },
        {
            "question": "Esperas a la campanada o subes inmediatamente cuando est\u00e1 abierta?"
        },
        {
            "question": "\u00bfQu\u00e9 has aprendido este a\u00f1o?"
        },
        {
            "question": "\u00bfTocas tus dientes mientras los limpias?"
        },
        {
            "question": "\u00bfCu\u00e1l estilo de ropa lo prohibir\u00edas?"
        },
        {
            "question": "\u00bfCuando la \u00faltima vez has llorado?"
        },
        {
            "question": "\u00bfEres una persona celosa?"
        },
        {
            "question": "La comida es muy deliciosa."
        },
        {
            "question": "Cu\u00e1l campo de la medicina te especializar\u00edas como m\u00e9dico?"
        },
        {
            "question": "Las amistades entre hombres son superficiales?"
        },
        {
            "question": "Quer\u00eda ser amigo/a de qui\u00e9n?"
        },
        {
            "question": "\u00bfCu\u00e1l frase dejar\u00edas en un gran espacio publicitario?"
        },
        {
            "question": "Qui\u00e9n ser\u00e1 famoso alg\u00fan d\u00eda?"
        },
        {
            "question": "\u00bfC\u00f3mo es tu saludo preferida?"
        },
        {
            "question": "En cinco a\u00f1os, \u00bfd\u00f3nde te veas t\u00fa?"
        },
        {
            "question": "\u00bfQu\u00e9 hubiese sido tu \u00faltima cena antes de ser ahorcado?"
        },
        {
            "question": "\u00bfQu\u00e9 gastas desmesuradamente?"
        },
        {
            "question": "\u00bfQu\u00e9 fue tu error de moda m\u00e1s avergonzoso?"
        },
        {
            "question": "\"\u00bfC\u00f3mo se dice 'Prost'?\""
        },
        {
            "question": "\u00bfHas ganado alguna vez una canasta antes?"
        },
        {
            "question": "\u00bfCu\u00e1l tipo de video de YouTube veo con regularidad?"
        },
        {
            "question": "En qu\u00e9 lugar es el m\u00fasculo contraccion m\u00e1s intenso/suave?"
        },
        {
            "question": "\"C\u00f3mo se ve tu bandera personal?\""
        },
        {
            "question": "Estuviste envidioso del juguete X."
        },
        {
            "question": "\u00bfCu\u00e1l es tu programa de televisi\u00f3n favorito?"
        },
        {
            "question": "\"C\u00f3mo te presenta el jubilado?\""
        },
        {
            "question": "Sabes de doblarlo?"
        },
        {
            "question": "\u00bfCu\u00e1l comida te gustar\u00eda probar cuando soy un adulto?"
        },
        {
            "question": "\u00bfQu\u00e9 cambiarias en tu educaci\u00f3n?"
        },
        {
            "question": "\u00bfHas estado sentido cool en situaciones durante tu etapa escolar?"
        },
        {
            "question": "\u00bfQu\u00e9 est\u00e1 haciendo Angela Merkel en este momento?"
        },
        {
            "question": "\"C\u00f3mo te fue la escuela de conducir?\""
        },
        {
            "question": "\u00bfCu\u00e1nto tiempo m\u00e1s ser\u00e1 Instagram divertido o de moda? (Literally: \"How much time more will Instagram be fun or trendy?\")"
        },
        {
            "question": "[The sentence translated into Spanish]"
        },
        {
            "question": "\u00bfQu\u00e9 te queda envidiando en otras culturas?"
        },
        {
            "question": "Cu\u00e1ndo pierden las parejas?"
        },
        {
            "question": "\"\u00bfCu\u00e1l es tu frase favorita de una pel\u00edcula?\""
        },
        {
            "question": "\u00bfTienes un tema para Pascua?"
        },
        {
            "question": "\u00bfSos r\u00e1pido/a?"
        },
        {
            "question": "[The sentence translated into Spanish]"
        },
        {
            "question": "\u00bfQu\u00e9 son tus defectos f\u00edsicos?"
        },
        {
            "question": "\u00bfQu\u00e9 es la m\u00e1s fuerte expresi\u00f3n para un amigo?"
        },
        {
            "question": "\u00bfQu\u00e9 disfrutaste m\u00e1s durante tus a\u00f1os en la escuela?"
        },
        {
            "question": "Nunca m\u00e1s alcohol ni caf\u00e9 para m\u00ed? (Never again alcohol or coffee for me?)"
        },
        {
            "question": "\u00bfDonde es m\u00e1s importante la higiene para ti: Ba\u00f1o o cocina?"
        },
        {
            "question": "\"McDonald's o Burger King?\""
        },
        {
            "question": "\"C\u00f3mo planear\u00eda una cita perfecta?\""
        },
        {
            "question": "\u00bfC\u00f3mo r\u00e1pido puedes llegar a los 180 grados?"
        },
        {
            "question": "Si, soy buen negociador."
        },
        {
            "question": "\u00bfC\u00f3mo lo haces cuando regresas a casa?"
        },
        {
            "question": "Para qu\u00e9 deporte recreacional no encajas?"
        },
        {
            "question": "\u00bfQu\u00e9 fue tu \u00faltima ojaleng\u00fceta?"
        },
        {
            "question": "\u00bfTe gusta m\u00e1s subestimarlo o sobrestimarlo?"
        },
        {
            "question": "Nunca has matado a alguien."
        },
        {
            "question": "\u00bfCu\u00e1l es tu medicamento favorito?"
        },
        {
            "question": "\"C\u00f3mo te gusta comer huevos de preferencia?\""
        },
        {
            "question": "[The sentence translated into Spanish]"
        },
        {
            "question": "\u00bfSigues relevante en la edad adulta?"
        },
        {
            "question": "\"\u00bfCU\u00e1l es el mejor reality de casting?\""
        },
        {
            "question": "\u00bfQu\u00e9 objeto manejas m\u00e1s a menudo en casa?"
        },
        {
            "question": "\u00bfCu\u00e1l es la abreviatura que uso regularmente?"
        },
        {
            "question": "\u00bfQu\u00e9 es la cosa m\u00e1s desagradable en tu departamento?"
        },
        {
            "question": "[The sentence translated into Spanish]"
        },
        {
            "question": "Cu\u00e1les ser\u00edan las bromas telef\u00f3nicas en tu tel\u00e9fono?"
        },
        {
            "question": "En qu\u00e9 lugar finds encontras cosas divertidas?"
        },
        {
            "question": "\u00bfQu\u00e9 esperas del viaje?"
        },
        {
            "question": "Cu\u00e1l especie de pez tiene un cuerpo alto?"
        },
        {
            "question": "Cu\u00e1l pez tiene un olor semejante a la pepinilla fresca?"
        },
        {
            "question": "\u00bfQu\u00e9 es una correa nasal?"
        },
        {
            "question": "\u00bfQu\u00e9 ser\u00eda tu trabajo so\u00f1ado alternativo?"
        },
        {
            "question": "\u00bfQu\u00e9 es tu olor favorito?"
        },
        {
            "question": "Tienes miedos irrazonables?"
        },
        {
            "question": "\u00bfCU\u00e1l pol\u00edtico es el mejor bailar\u00edn?"
        },
        {
            "question": "\u00bfCu\u00e1l es tu momento favorito de multitarea mezclado?"
        },
        {
            "question": "\u00bfQu\u00e9 sorprende por su funcionalidad en lo tecnol\u00f3gico?"
        },
        {
            "question": "\u00bfCu\u00e1l es tu peor juego de palabras?"
        },
        {
            "question": "Cu\u00e1l insecto est\u00e1 por encima de todo? (Literally: Which insect is above all?)"
        },
        {
            "question": "\u00bfQu\u00e9 desagradas t\u00fa, qu\u00e9 otros encuentran genial?"
        },
        {
            "question": "En qu\u00e9 asunto eres un campesino?"
        },
        {
            "question": "Preguntas a tus padres sobre qu\u00e9 temas?"
        },
        {
            "question": "\u00bfCu\u00e1l especie de oso finds m\u00e1s impresionante?"
        },
        {
            "question": "\u00bfCu\u00e1l regla de cortes\u00eda es importante para usted?"
        },
        {
            "question": "\u00bfQu\u00e9 te gustar\u00eda poder hacer?"
        },
        {
            "question": "\"\u00bfC\u00f3mo ser\u00eda el lema de tu marca?\""
        },
        {
            "question": "\u00bfCu\u00e1l fue el mejor cumplea\u00f1os de tu hijo/hija?"
        },
        {
            "question": "\u00bfCuando por \u00faltimo tuviste una buena carcajada?"
        },
        {
            "question": "\u00bfCu\u00e1l disco tienes una relaci\u00f3n especial?"
        },
        {
            "question": "\u00bfQu\u00e9 podr\u00edas repentinamente hacer bien?"
        },
        {
            "question": "Tuvo una vez una llamada que cambi\u00f3 tu vida de forma significativa?"
        },
        {
            "question": "En que deporte ol\u00edmpico no ser\u00eda malo?"
        },
        {
            "question": "Para qu\u00e9 har\u00edas publicidad?"
        },
        {
            "question": "Si la moneda no fuera un factor, \u00bfqu\u00e9 profesi\u00f3n eligir\u00edas?"
        },
        {
            "question": "\u00bfQu\u00e9 es lo m\u00e1s provisional en tu vivienda?"
        },
        {
            "question": "\"C\u00f3mo vieron o percibieron tus amigos tus padres?\""
        },
        {
            "question": "En qu\u00e9 productos eres leal a la marca?"
        },
        {
            "question": "[The sentence translated into Spanish]"
        },
        {
            "question": "\u00bfQu\u00e9 tiene de infantil en ti?"
        },
        {
            "question": "El sol se pone detr\u00e1s de las monta\u00f1as."
        },
        {
            "question": "Cu\u00e1l proceso m\u00edo no cambiar\u00e1 m\u00e1s?"
        },
        {
            "question": "\u00bfPuedes cambiar una rueda de coche?"
        },
        {
            "question": "\u00bfQu\u00e9 hubiese inmediatamente gastado dinero en?"
        },
        {
            "question": "Si pudiese, cu\u00e1l ley har\u00eda para Alemania?"
        },
        {
            "question": "\u00bfQui\u00e9n es la persona m\u00e1s amable que conoces?"
        },
        {
            "question": "\u00bfQu\u00e9 h\u00e1bito alimenticio desagradable tienes t\u00fa?"
        },
        {
            "question": "\u00bfCu\u00e1les son los mejores tipos de alcohol?"
        },
        {
            "question": "\"\u00bfCu\u00e1l es 17 veces 9?\""
        },
        {
            "question": "Cu\u00e1l libro de tu estante no has le\u00eddo?"
        },
        {
            "question": "S\u00ed, puedes pintar."
        },
        {
            "question": "El tema es la decoraci\u00f3n navide\u00f1a: S\u00ed o no?"
        },
        {
            "question": "\u00bfQu\u00e9 hab\u00eda sido lo m\u00e1s impresionante en tu vida hasta ahora?"
        },
        {
            "question": "En veinti\u00fan a\u00f1os, \u00bfqu\u00e9 tipo de persona ser\u00e9 en las fiestas familiares?"
        },
        {
            "question": "Eso lo quiero absolutamente a\u00fan aprender."
        },
        {
            "question": "\"C\u00f3mo se ve la portada de tu biograf\u00eda?\""
        },
        {
            "question": "\u00bfCu\u00e1l es tu p\u00e1jaro favorito?"
        },
        {
            "question": "\u00bfQu\u00e9 hubiste de orinar h\u00e1bilmente recientemente?"
        },
        {
            "question": "\u00bfCu\u00e1l es el tipo de ropa deportiva que soy?"
        },
        {
            "question": "Al comer fuera de casa, califica platos y a ti mismo?"
        },
        {
            "question": "\u00bfCu\u00e1l es tu apodo m\u00e1s verg\u00fcenzoso?"
        },
        {
            "question": "\u00bfQu\u00e9 has preparado en tu departamento solo para tus hu\u00e9spedes?"
        },
        {
            "question": "\u00bfQu\u00e9 piensas sobre el nombre Ren\u00e9?"
        },
        {
            "question": "\u00bfQu\u00e9 anhelas de Alemania mientras est\u00e1s en el extranjero?"
        },
        {
            "question": "\u00bfTienes un lugar donde siempre dejas cosas?"
        },
        {
            "question": "\u00bfQu\u00e9 es tu opini\u00f3n desfavorable?"
        },
        {
            "question": "\u00bfQu\u00e9 es oro olvidado en Internet?"
        },
        {
            "question": "\u00bfQu\u00e9 te parece absurdamente arrogante?"
        },
        {
            "question": "Qui\u00e9n gasta m\u00e1s tu tiempo?"
        },
        {
            "question": "\u00bfCu\u00e1l fue asunto escolar innecesario?"
        },
        {
            "question": "Adoro el Germaetespeck con compota de frutas."
        },
        {
            "question": "El pastel de chocolate es hermoso y sabroso. [El pastel de chocolate is hermoso y sabroso.]"
        },
        {
            "question": "\u00bfQu\u00e9 es una cocina t\u00edpica de padres en casa?"
        },
        {
            "question": "\u00bfQu\u00e9 hace que alguien parezca erudito?"
        },
        {
            "question": "[The sentence translated into Spanish]"
        },
        {
            "question": "\u00bfC\u00f3mo pila tus restos de comida: Cort\u00e9s o honestamente?"
        },
        {
            "question": "\u00bfQu\u00e9 compra de placer culpable durante las cat\u00e1strofes?"
        },
        {
            "question": "\u00bfQu\u00e9 acci\u00f3n fue la m\u00e1s asocial que realizaste en la fiesta de casa?"
        },
        {
            "question": "\"Es un clavo o una hueco?\" (Is it a nail or a hole?)"
        },
        {
            "question": "\u00bfQu\u00e9 es una novedad t\u00e9cnica que funciona de forma sencilla?"
        },
        {
            "question": "\"C\u00f3mo se establece la fecha de Corona? (C\u00f3mo es la fecha de referencia de Corona?)\": \"How is the Corona date set? (What is the Corona reference date?)\"."
        },
        {
            "question": "\u00bfCu\u00e1l insulta pol\u00edticamente incorrecta sigue saliendo a la luz para m\u00ed?"
        },
        {
            "question": "\u00bfTienes una conexi\u00f3n con un modelo de autom\u00f3vil espec\u00edfico?"
        },
        {
            "question": "\u00bfQu\u00e9 hubiese gustado haber empezado mucho antes?"
        },
        {
            "question": "Dame la pelota roja que tiene tres huevos."
        },
        {
            "question": "\u00bfPuedes caminar a cuatro patas? (or) \u00bfSabes nadar de rodillas?"
        },
        {
            "question": "\u00bfQu\u00e9 no se encuentra entre tus tres cosas preferidas?"
        },
        {
            "question": "\u00bfQu\u00e9 fue el mayor error de tu infancia?"
        },
        {
            "question": "[The sentence translated into Spanish]"
        },
        {
            "question": "\u00bfQu\u00e9 es tu licor preferido para casa despu\u00e9s de la cena?"
        },
        {
            "question": "\u00bfQu\u00e9 estabas haciendo en una tarde de verano de 2006?"
        },
        {
            "question": "\u00bfQu\u00e9 juguete preferes para ba\u00f1os?"
        },
        {
            "question": "\u00bfQu\u00e9 planes tienes para las vacaciones de verano?"
        },
        {
            "question": "Me gusta el pollo asado en la temporada del verano."
        },
        {
            "question": "\u00bfQu\u00e9 te causa m\u00e1s estr\u00e9s: Demasiado claro o demasiado oscuro?"
        },
        {
            "question": "\"C\u00f3mo te sentis sobre Windowcolour?\""
        },
        {
            "question": "En qu\u00e9 canci\u00f3n quieres estar borracho inmediatamente?"
        },
        {
            "question": "Cu\u00e1l pista deber\u00eda seguirme m\u00e1s a m\u00ed personalmente?"
        },
        {
            "question": "Por cu\u00e1les productos eliges la opci\u00f3n m\u00e1s econ\u00f3mica?"
        },
        {
            "question": "Crees en el karma? (Literally: \"Do you believe in the action?\")"
        },
        {
            "question": "Caballo o pollo (for horse) or Gallina or Pollo (for chicken)"
        },
        {
            "question": "Me gustan papas machadas con salsa de grasa. [I love mashed potatoes with gravy]"
        },
        {
            "question": "\u00bfCu\u00e1l es tu billete preferido? [What is your preferred banknote?]"
        },
        {
            "question": "\u00bfC\u00f3mo se llama la casa en esquina?"
        },
        {
            "question": "\u00bfTienes demasiado de lo que mencionas?"
        },
        {
            "question": "Cu\u00e1l sue\u00f1o nunca lograr\u00e1s?"
        },
        {
            "question": "\u00bfTiene el arquitectura alg\u00fan efecto sobre ti?"
        },
        {
            "question": "Por cu\u00e1l anuncio me he encontrado m\u00e1s recientemente?"
        },
        {
            "question": "La comida es muy deliciosa."
        },
        {
            "question": "\u00bfQu\u00e9 estudiar\u00edas?"
        },
        {
            "question": "\u00bfQu\u00e9 hubiese sido tu profesi\u00f3n en el a\u00f1o 1880?"
        },
        {
            "question": "\u00bfTiene una tradici\u00f3n familiar t\u00fa?"
        },
        {
            "question": "\u00bfQu\u00e9 momentos te hacen feliz?"
        },
        {
            "question": "\u00bfQu\u00e9 mueble podr\u00eda construir?"
        },
        {
            "question": "\u00bfCalentas antes de hacer deportes?"
        },
        {
            "question": "\u00bfQu\u00e9 producto retir\u00f3 el mercado?"
        },
        {
            "question": "\u00bfCU\u00e1l palabra de extranjero usas m\u00e1s a menudo en este momento?"
        },
        {
            "question": "\u00bfCu\u00e1l de tus caracter\u00edsticas hace dif\u00edcil vivir juntos?"
        },
        {
            "question": "\u00bfQu\u00e9 te hac\u00eda feliz cuando eras un ni\u00f1o/a?"
        },
        {
            "question": "[The sentence translated into Spanish]"
        },
        {
            "question": "Tu has tenido una retenes dental?"
        },
        {
            "question": "\"Cu\u00e1nto dinero es el 'Fuck-You' money?\""
        },
        {
            "question": "Cu\u00e1l es la empresa con un nombre extra\u00f1o pero adecuado?"
        },
        {
            "question": "\"C\u00f3mo duermes en este momento?\""
        },
        {
            "question": "Qu\u00e9 deseas, pero lo que no es permitido."
        },
        {
            "question": "\u00bfCu\u00e1l errar\u00eda usted como padre/madre para garantizar la seguridad?"
        },
        {
            "question": "\u00bfPensas que estamos acostumbr\u00e1ndonos a la pandemia?"
        },
        {
            "question": "\u00bfCu\u00e1l es la frase que uso demasiado a menudo?"
        },
        {
            "question": "\"C\u00f3mo hiciste tu primer gol?\""
        },
        {
            "question": "En qu\u00e9 ciudad alemana te sientes peor?"
        },
        {
            "question": "\u00bfCu\u00e1l es la pel\u00edcula que has visto m\u00e1s veces?"
        },
        {
            "question": "[The sentence translated into Spanish]"
        },
        {
            "question": "\u00bfCu\u00e1l es tu panader\u00eda preferida?"
        },
        {
            "question": "\u00bfTe gusta la sushi?"
        },
        {
            "question": "Donde se ven las m\u00e1scaras m\u00e1s elegantemente."
        },
        {
            "question": "\u00bfQu\u00e9 elementos cinematogr\u00e1ficos resultan extra\u00f1os en la vida real?"
        },
        {
            "question": "\u00bfCu\u00e1l es tu juego de ordenador favorito?"
        },
        {
            "question": "Hay una tarea a la que otros la encuentran est\u00fapida, pero t\u00fa la disfrutas?"
        },
        {
            "question": "\u00bfQu\u00e9 te animas m\u00e1s en este momento?"
        },
        {
            "question": "\u00bfQu\u00e9 no entendes en tu cuerpo?"
        },
        {
            "question": "\u00bfCu\u00e1l coche encontr\u00e1s visualmente m\u00e1s atractivo?"
        },
        {
            "question": "\u00bfQu\u00e9 debo realmente hacer?"
        },
        {
            "question": "Lunes o mi\u00e9rcoles: \u00bfCu\u00e1l es el peor d\u00eda? (Note: In this context, \"Dienstag\" in German translates to \"mi\u00e9rcoles\" in Spanish, which means \"Wednesday\")"
        },
        {
            "question": "\u00bfCu\u00e1l publicaci\u00f3n no privada te molesta m\u00e1s? [Question mark for \"most\"]"
        },
        {
            "question": "\u00bfQu\u00e9 momentos de mi infancia no me faltan en absoluto?"
        },
        {
            "question": "[The sentence translated into Spanish]"
        },
        {
            "question": "\u00bfQu\u00e9 cosa actualmente es una cosa existente, seguir\u00e1 existiendo en 50 a\u00f1os?"
        },
        {
            "question": "\u00bfCu\u00e1l es tu pieza de mobiliario favorita?"
        },
        {
            "question": "\u00bfQu\u00e9 es la mejor opci\u00f3n de ensalada aqu\u00ed?"
        },
        {
            "question": "\u00bfQu\u00e9 a\u00fan es inconcebible?"
        },
        {
            "question": "\u00bfQu\u00e9 te muestra actualmente el algoritmo en las redes sociales?"
        },
        {
            "question": "\u00bfQu\u00e9 es lo m\u00e1s infantil de ti en este momento?"
        },
        {
            "question": "\u00bfQu\u00e9 es el juego de mesa mejor?"
        },
        {
            "question": "\u00bfQui\u00e9n es el m\u00e1s divertido de los celebridades alemanes que no son comediantes?"
        },
        {
            "question": "Cu\u00e1l tipo de grupos de Mensajero es el peor?"
        },
        {
            "question": "\u00bfTienes un traumatismo alimenticio?"
        },
        {
            "question": "\u00bfQu\u00e9 har\u00edas acumulando cuando llegue el momento?"
        },
        {
            "question": "\u00bfTienes una balada preferida?"
        },
        {
            "question": "\u00bfCu\u00e1l pa\u00eds ser\u00eda adecuado para ti emigrar all\u00ed?"
        },
        {
            "question": "\u00bfCu\u00e1l pa\u00eds no te interesa en este momento?"
        },
        {
            "question": "Cuando actualmente experimentas odio a ti mismo?"
        },
        {
            "question": "\"C\u00f3mo se ve tu D\u00eda de Excepciones perfecto?\""
        },
        {
            "question": "Has crecido algo realmente tonto?"
        },
        {
            "question": "\u00bfCu\u00e1l broma rid\u00edcula encontr\u00e1s realmente graciosa?"
        },
        {
            "question": "\u00bfCu\u00e1l fue tu coche so\u00f1ado para comprar tu primero?"
        },
        {
            "question": "Cu\u00e1l cadena de restaurantes prefieres?"
        },
        {
            "question": "En qu\u00e9 tema est\u00e1s realizando una b\u00fasqueda intensiva en Google actualmente, a pesar de no poderlo satisfacer?"
        },
        {
            "question": "Qui\u00e9n mira siempre cuando est\u00e1 all\u00ed?"
        },
        {
            "question": "\u00bfQu\u00e9 cosas le gusta que haga la otra persona con otras personas, prefiero hacerlo solo?"
        },
        {
            "question": "Preferis hacer cosas con otras personas en lugar de solo."
        },
        {
            "question": "\u00bfQu\u00e9 recomendaci\u00f3n dar\u00edas a un ni\u00f1o pobre y peque\u00f1o?"
        },
        {
            "question": "\"Qu\u00e9 te hace sentir melanc\u00f3lico de manera inesperada?\""
        },
        {
            "question": "[The sentence translated into Spanish]"
        },
        {
            "question": "\u00bfQu\u00e9 haces de otra forma cuando no hay nada para mirar mientras comemos?"
        },
        {
            "question": "\u00bfQu\u00e9 hiciste cuando te faltabas a la escuela?"
        },
        {
            "question": "\u00bfQu\u00e9 te gusta hacer para otros y por qu\u00e9?"
        },
        {
            "question": "El experimento fue un \u00e9xito, a pesar de que estuimos muy cerca del punto de fusi\u00f3n con la temperatura."
        },
        {
            "question": "Tienes nueve minutos para relajarte: \u00bfQu\u00e9 bebes?"
        },
        {
            "question": "Tienes 9 minutos para un entrenamiento: \u00bfCu\u00e1les ejercicios har\u00e1s para agotarte?"
        },
        {
            "question": "\u00bfCu\u00e1l fue tu \u00faltima buena intenci\u00f3n que realmente fall\u00f3?"
        },
        {
            "question": "Cu\u00e1les de mis intenciones han ido adelante?"
        },
        {
            "question": "Por qu\u00e9 podr\u00eda despertarme a las 3 de la madana?"
        },
        {
            "question": "T\u00fa siempre tuviste algo en el coche."
        },
        {
            "question": "\u00bfPuedes masajear (rasgar/deslizar) bien?"
        },
        {
            "question": "Si fuera una mujer o un hombre, cu\u00e1l ser\u00eda mi peluquita? (Literally: Which would be my little hairstyle?)"
        },
        {
            "question": "\u00bfQuieres platos shallows o profundos?"
        },
        {
            "question": "\u00bfCu\u00e1l comida debe tener cero calor\u00edas?"
        },
        {
            "question": "\u00bfCu\u00e1nto durar\u00e1 el viaje a Asia?"
        },
        {
            "question": "\u00bfQu\u00e9 medicamento de droger\u00eda innecesario tienes en tu posesi\u00f3n?"
        },
        {
            "question": "\u00bfQu\u00e9 fue el mejor error que he cometido en mi vida?"
        },
        {
            "question": "Has encontrado alguna cosa algunas veces? (Literally: \"Have you found something some times?\")"
        },
        {
            "question": "\u00bfQu\u00e9 tarea no puedes hacer lo menos?"
        },
        {
            "question": "\u00bfQu\u00e9 deseo adicional tienes a menudo, que podr\u00eda estar en el men\u00fa de tu restaurante?"
        },
        {
            "question": "Cu\u00e1l parte del cuerpo humano encontrar\u00eda m\u00e1s deliciosa, si fuera un can\u00edbal?"
        },
        {
            "question": "\u00bfQu\u00e9 te provoca m\u00e1s en discusiones calorosas?"
        },
        {
            "question": "Cu\u00e1ndo a\u00fan tienes silencio en tu vida? (Literally: \"When still you have silence in your life?\")"
        },
        {
            "question": "\u00bfCu\u00e1l es tu plataforma de streaming preferida actualmente?"
        },
        {
            "question": "No, no puedo fabricar o hacer cosas."
        },
        {
            "question": "Cu\u00e1ndo te sentiste m\u00e1s alem\u00e1n de verdad por \u00faltimo?"
        },
        {
            "question": "\"C\u00f3mo te queda la rutina de la noche?\""
        },
        {
            "question": "\u00bfQu\u00e9 compras siempre por primera vez cuando est\u00e1 tarde?"
        },
        {
            "question": "\u00bfQu\u00e9 permitir\u00edas ver a tus hijos?"
        },
        {
            "question": "\u00bfQu\u00e9 fue el \u00faltimo art\u00edculo decorativo que compraste recientemente?"
        },
        {
            "question": "\u00bfHas estado mal asustado?"
        },
        {
            "question": "\u00bfHas recibido alguna vez consejos de vida t\u00f3xicos?"
        },
        {
            "question": "Cu\u00e1l anuncio te ha molestado m\u00e1s recientemente?"
        },
        {
            "question": "\"C\u00f3mo te reacts cuando alguien olvida tu cumplea\u00f1os?\""
        },
        {
            "question": "Debes adquirir una mascota que no sea perro ni gato. \u00bfCu\u00e1l la elegir\u00e1s?"
        },
        {
            "question": "\u00bfCu\u00e1l es el grupo muscular que menos te gusta entrenar?"
        },
        {
            "question": "\u00bfC\u00f3mo lo pasaste tu decimo octavo cumplea\u00f1os?"
        },
        {
            "question": "En qu\u00e9 establecimiento de comida externa no se puede equivocar?"
        },
        {
            "question": "\u00bfQu\u00e9 finds desagradablemente femenino o inapropiadamente masculino?"
        },
        {
            "question": "\u00bfTienes una serie favorita en este momento?"
        },
        {
            "question": "\u00bfHay gente a la que t\u00fa tienes envidia?"
        },
        {
            "question": "\u00bfCu\u00e1l es el mejor tipo de fideos?"
        },
        {
            "question": "Lo que es moda hoy en d\u00eda, lo que ser\u00e1 verg\u00fcenza en quince a\u00f1os."
        },
        {
            "question": "\u00bfCu\u00e1l es tu emoji favorito actualmente?"
        },
        {
            "question": "Ser\u00edas una persona pol\u00edtica?"
        },
        {
            "question": "\u00bfQu\u00e9 te hizo politicamente activo?"
        },
        {
            "question": "\u00bfQu\u00e9 es lo m\u00e1s asustadizo de tu departamento?"
        },
        {
            "question": "\u00bfQu\u00e9 es tu saboreado preferido?"
        },
        {
            "question": "[The sentence translated into Spanish]"
        },
        {
            "question": "Cu\u00e1l famoso persona le gustar\u00eda dar un trato de peluqueria?"
        },
        {
            "question": "\u00bfQu\u00e9 fue un elogio gracioso que me recibiste?"
        },
        {
            "question": "\u00bfTienes una historia interesante de conocer a alguien?"
        },
        {
            "question": "\u00bfCu\u00e1l deporte encontras muy atractivo, pero nunca lo has practicado?"
        },
        {
            "question": "\u00bfCu\u00e1l es tu r\u00e9cord personal de cerveza por d\u00eda?"
        },
        {
            "question": "Tu canci\u00f3n m\u00e1s escuchada en el a\u00f1o 2022 fue..."
        },
        {
            "question": "\u00bfQui\u00e9n es tu presentador de noticias preferido?"
        },
        {
            "question": "\u00bfQu\u00e9 juguete prefer\u00edas cuando eras un ni\u00f1o/a?"
        },
        {
            "question": "\"C\u00f3mo poca importancia tiene usted.\""
        },
        {
            "question": "\u00bfHay personas a las que tengas un mal sentimiento de culpa?"
        },
        {
            "question": "\u00bfQu\u00e9 piensas de la aplicaci\u00f3n BeReal?"
        },
        {
            "question": "\u00bfQu\u00e9 te gusta m\u00e1s de ti a tus padres?"
        },
        {
            "question": "\u00bfQu\u00e9 te ha estado molestando a\u00fan a tus padres?"
        },
        {
            "question": "\u00bfHas utilizado alguna vez una cortac\u00e9sped?"
        },
        {
            "question": "\u00bfCu\u00e1l fue el \u00faltimo concierto que asististe?"
        },
        {
            "question": "[The sentence translated into Spanish]"
        },
        {
            "question": "[The sentence translated into Spanish]"
        },
        {
            "question": "\u00bfQu\u00e9 metes en tu pan de raclette?"
        },
        {
            "question": "\u00bfQu\u00e9 hizo que tu a\u00f1o 2022 fuera memorable?"
        },
        {
            "question": "\u00bfQu\u00e9 deseas recibir para Navidad?"
        },
        {
            "question": "Donde debes vivir para estar el m\u00e1s feliz? (Literally: \"Where do you have to live to be the most happy?\")"
        },
        {
            "question": "En qu\u00e9 tema te llevas demasiado lejos y te haces muy alegre? (In which topic do you carry yourself too far and make yourself very happy?)"
        },
        {
            "question": "\u00bfQu\u00e9 es el vegetal m\u00e1s recomendado o delicioso en tus opiniones? (What is the most recommended or delicious vegetable in your opinion?)"
        },
        {
            "question": "\u00bfFue una bromista en la escuela?"
        },
        {
            "question": "[The sentence translated into Spanish]"
        },
        {
            "question": "Sabes algo en qu\u00e9 est\u00e1s seguro de que lo haces mejor que la gente de alrededor?"
        },
        {
            "question": "\u00bfCu\u00e1l es el tiempo en que te sientes m\u00e1s vivo/a?"
        },
        {
            "question": "Cu\u00e1l grupo profesional est\u00e1 realmente experimentando el estr\u00e9s ahora?"
        },
        {
            "question": "\u00bfQui\u00e9n es el hombre m\u00e1s hermoso en Alemania?"
        },
        {
            "question": "En qu\u00e9 asunto est\u00e1s realmente feliz de no tener que tratarlo m\u00e1s?"
        },
        {
            "question": "\"Lo que ya no te gusta por tu edad.\""
        },
        {
            "question": "\u00bfQu\u00e9 siempre me despertaza la emoci\u00f3n?"
        },
        {
            "question": "\u00bfCu\u00e1l fue la \u00faltima vez que estableciste una rutina?"
        },
        {
            "question": "Explicas Carnaval a un musulmano creyente como un cuervo."
        },
        {
            "question": "No tienes nada adecuado y necesitas comprar un traje completo, \u00bfd\u00f3nde vas a comprar?"
        },
        {
            "question": "\"\u00bfQu\u00e9 consideras una buena pluma para ti?\""
        },
        {
            "question": "\u00bfCu\u00e1l fue la \u00faltima vez que t\u00fa fuiste valiente?"
        },
        {
            "question": "\u00bfQu\u00e9 suceder\u00eda en el mundo de la fitness si el pen\u00eds se hiciera m\u00e1s gordo al aumentar tama\u00f1o?"
        },
        {
            "question": "\u00bfQu\u00e9 ya no es rentable?"
        },
        {
            "question": "\u00bfQu\u00e9 decoraci\u00f3n tienes en el reloj de visitantes?"
        },
        {
            "question": "\"\u00bfQu\u00e9 tema m\u00e1s aburrido hay para documentales?\""
        },
        {
            "question": "\u00bfCu\u00e1l fue la \u00faltima vez que te sentiste realmente entretenido(a)?"
        },
        {
            "question": "\u00bfC\u00f3mo describir\u00eda mi estilo de conducir?"
        },
        {
            "question": "\u00bfD\u00f3nde eres avaro?"
        },
        {
            "question": "\u00bfCu\u00e1l es la profesi\u00f3n de artesan\u00eda m\u00e1s impresionante?"
        },
        {
            "question": "C\u00f3mo te reacts a un precio demasiado alto?"
        },
        {
            "question": "\u00bfQu\u00e9 te toca emocionalmente siempre?"
        },
        {
            "question": "\u00bfQu\u00e9 piensas de la ensalada de carne?"
        },
        {
            "question": "Si tuvieras que encontrar un pasatiempo caro para evitar morir, cu\u00e1l eligir\u00eda usted?"
        },
        {
            "question": "Si fuera un pol\u00edtico, usar\u00eda la red social activamente?"
        },
        {
            "question": "\u00bfQu\u00e9 admires sobre los espa\u00f1oles?"
        },
        {
            "question": "\u00bfQu\u00e9 es el tipo de salchicha m\u00e1s desagradable?"
        },
        {
            "question": "\u00bfCu\u00e1l es tu se\u00f1al de tr\u00e1fico preferida?"
        },
        {
            "question": "\u00bfCu\u00e1l era tu personaje favorito de historietas cuando eras un ni\u00f1o?"
        },
        {
            "question": "\u00bfQu\u00e9 est\u00e1 por venir para ti que no te animas?"
        },
        {
            "question": "\u00bfQu\u00e9 probablemente te hubiese matado si no hubiera medicina?"
        },
        {
            "question": "Usar\u00eda una bolsa de kanguro por la noche si tuviera un perro?"
        },
        {
            "question": "\u00bfQu\u00e9 es nuestro \"G\u00e9nero\" cuando tenemos 60 a\u00f1os?"
        },
        {
            "question": "\u00bfTienes comentarios no solicitados sobre un producto?"
        },
        {
            "question": "\u00bfQu\u00e9 producto est\u00e1s buscando siempre en el supermercado?"
        },
        {
            "question": "\"Cargar o descargar la licuadora de platos?\""
        },
        {
            "question": "Cu\u00e1l lujo har\u00edas construir en tu propia casa?"
        },
        {
            "question": "\u00bfQu\u00e9 es el producto frito m\u00e1s chulo?"
        },
        {
            "question": "Cu\u00e1l bomba de energ\u00eda desconoces usted?"
        },
        {
            "question": "\u00bfQu\u00e9 prenda de verano te gusta mejor?"
        },
        {
            "question": "\u00bfTienes un destino de viaje aparentemente extra\u00f1o?"
        },
        {
            "question": "A veces tienes el deseo de realizar grandes actividades de verano?"
        },
        {
            "question": "[The sentence translated into Spanish]"
        },
        {
            "question": "\u00bfQu\u00e9 te caus\u00f3 inquietud acerca de ti en este momento?"
        },
        {
            "question": "No te interesan las letras de la m\u00fasica alemana."
        },
        {
            "question": "\u00bfQu\u00e9 costumbre social sigues sin preguntas?"
        },
        {
            "question": "Tienen ustedes costumbres que no funcionan? [Formal]"
        },
        {
            "question": "\u00bfCu\u00e1l es tu canci\u00f3n favorita de R&B?"
        },
        {
            "question": "\u00bfQu\u00e9 est\u00e1s viviendo en tu crisis de mediana edad?"
        },
        {
            "question": "\u00bfQu\u00e9 podr\u00eda hacer que los ni\u00f1os lo hicieran todos los d\u00edas?"
        },
        {
            "question": "\u00bfC\u00f3mo te gusta ofender a alguien mientras manejas? (How do you like to offend someone while driving?)"
        },
        {
            "question": "\u00bfCu\u00e1l es tu personaje favorito en Mario Kart?"
        },
        {
            "question": "\u00bfCu\u00e1l es el tuyo preferido de angelfishes de agua dulce?"
        },
        {
            "question": "[The sentence translated into Spanish]"
        },
        {
            "question": "\u00bfQu\u00e9 es la mayor equivoci\u00f3n de moda para los hombres?"
        },
        {
            "question": "\u00bfQu\u00e9 encontraste en la gruta de los leones?"
        },
        {
            "question": "\u00bfEn qu\u00e9 dispositivo t\u00e9cnico podr\u00edamos renunciar m\u00e1s f\u00e1cilmente?"
        },
        {
            "question": "\u00bfQu\u00e9 tema preferido tienes sobre Cr\u00edmen Verdadero?"
        },
        {
            "question": "Has estado alguna vez en un festival?"
        },
        {
            "question": "En qu\u00e9 momentos quieres ser visto en todo caso?"
        },
        {
            "question": "\u00bfQu\u00e9 hay sobre el plater\u00eda: la manija arriba o abajo cuando se carga la lavadora de platos?"
        },
        {
            "question": "\u00bfCu\u00e1l es tu animnal favorito del bosque alem\u00e1n?"
        },
        {
            "question": "\u00bfTienes una l\u00ednea de rap preferida?"
        },
        {
            "question": "Cu\u00e1l producto de otro pa\u00eds debe estar necesariamente disponible en Alemania?"
        },
        {
            "question": "Por qu\u00e9 la gente tosa tan a menudo?"
        },
        {
            "question": "\u00bfQu\u00e9 ha tenido durante el tiempo m\u00e1s largo, lo que a\u00fan utiliza?"
        },
        {
            "question": "Tu \u00faltima desilusi\u00f3n real fue lo que? [Your last real disappointment was what?]"
        },
        {
            "question": "\u00bfCu\u00e1l es tu pastel favorito?"
        },
        {
            "question": "Crees que el estado de \u00e1nimo general seguir\u00e1 siendo el mismo, o cambiar\u00e1 algo?"
        },
        {
            "question": "\u00bfCu\u00e1l es la temperatura que m\u00e1s molesta?"
        },
        {
            "question": "\u00bfTienes un paraguas?"
        },
        {
            "question": "\u00bfCu\u00e1l es tu estaci\u00f3n favorita en una estaci\u00f3n de lavado de coches?"
        },
        {
            "question": "\u00bfDe cu\u00e1l caracter\u00edstica adulta te sent\u00eds orgulloso?"
        },
        {
            "question": "En qu\u00e9 actividad te sientes m\u00e1s c\u00f3modo o chic?"
        },
        {
            "question": "Cu\u00e1l fue el \u00faltimo producto de farmacia que usaste en tu rutina diaria?"
        },
        {
            "question": "Cu\u00e1l pol\u00edtico malo o dictador resulta m\u00e1s atractivo?"
        },
        {
            "question": "Debes deslizarte en la delinquencia: \u00bfqu\u00e9 har\u00edas?"
        },
        {
            "question": "Cu\u00e1les son los atletas con cuerpos m\u00e1s hermosos?"
        },
        {
            "question": "\u00bfCu\u00e1l es tu frase favorita que te has citado t\u00fa mismo?"
        },
        {
            "question": "\u00bfQu\u00e9 te viene a la mente por primera vez al o\u00edr \"Maldici\u00f3n y bendici\u00f3n\"?"
        },
        {
            "question": "\u00bfQu\u00e9 es el mejor revestimiento de piso?"
        },
        {
            "question": "\u00bfEn qu\u00e9 sentido eres conservador y valioso?"
        },
        {
            "question": "\u00bfTienes demasiada cantidad \u00fanica de lo que mencionas?"
        },
        {
            "question": "En qu\u00e9 videos insensatas y molestos de tiempo pasar has estado recientemente encogido?"
        },
        {
            "question": "\u00bfCu\u00e1l es tu color favorito para: Coche, Camiseta, Perro y Polpa\u00f1uelos para mujeres?"
        },
        {
            "question": "Frente a cu\u00e1l erratum, que he cometido varias veces antes, te gustar\u00eda advertir a otros hablando de ello ahora?"
        },
        {
            "question": "Por qu\u00e9 vivis en una gran ciudad? (o en el campo)"
        },
        {
            "question": "Cu\u00e1l proceso de limpieza te hace sentir el m\u00e1s satisfecho?"
        },
        {
            "question": "\u00bfQu\u00e9 eran tus calificaciones de mano de obra en el Abitur?"
        },
        {
            "question": "\u00bfC\u00f3mo se puede encontrar nuevos amigos cuando uno es adulto?"
        },
        {
            "question": "Pensan que eres bueno en algo, no es verdad?"
        },
        {
            "question": "\u00bfQu\u00e9 pastel est\u00e1 m\u00e1s sobrevalorado?"
        },
        {
            "question": "\u00bfCu\u00e1l es el pa\u00eds con la bandera m\u00e1s hermosa?"
        },
        {
            "question": "Cu\u00e1l producto debe tener cero calor\u00edas a partir de ahora?"
        },
        {
            "question": "\u00bfQu\u00e9 tipo de maestro soy?"
        },
        {
            "question": "\u00bfCu\u00e1l es tu postre preferido para la noche?"
        },
        {
            "question": "Cu\u00e1l animal te gustar\u00eda tener como mascota?"
        },
        {
            "question": "Estoy gozando de las buenas noticias."
        },
        {
            "question": "\u00bfCompras t\u00fa mismo un regalo para Navidad?"
        },
        {
            "question": "Cu\u00e1l lugar tur\u00edstico te gustar\u00eda ver alg\u00fan d\u00eda?"
        },
        {
            "question": "\u00bfQu\u00e9 crees que mi actitud hacia la juventud ser\u00e1, al ser un jubilado?"
        },
        {
            "question": "Tuviste h\u00e9roes o \u00eddolos en la infancia?"
        },
        {
            "question": "Cu\u00e1les de los utensilios de cocina quedan afuera y cu\u00e1les entran inmediatamente?"
        },
        {
            "question": "En qu\u00e9 tipo de pelota hay una mejor opci\u00f3n para el uso libre de ocupaci\u00f3n?"
        },
        {
            "question": "Por qu\u00e9 idea, que una vez tuviste, est\u00e1s feliz de que se entendi\u00f3?"
        },
        {
            "question": "\u00bfQu\u00e9 compras en el est\u00e1nter de las olivas?"
        },
        {
            "question": "\u00bfCu\u00e1l es el deporte m\u00e1s divertido?"
        },
        {
            "question": "Cu\u00e1l es tu intro de serie infantil preferido de todas las tiempo?"
        },
        {
            "question": "\"C\u00f3mo cortas el pan?\""
        },
        {
            "question": "\u00bfCu\u00e1ndo has lastima intencionadamente a alguien por \u00faltima vez?"
        },
        {
            "question": "\u00bfQu\u00e9 es la combinaci\u00f3n alimentaria m\u00e1s divertida que alguien en tu entorno tiene?"
        },
        {
            "question": "\u00bfQu\u00e9 hora es la mejor de la semana? (What is the best hour of the week?) or \u00bfEn qu\u00e9 horas es mejor la semana? (In what hours is it better the week?) Depending on the context, both translations could be correct."
        },
        {
            "question": "[The sentence translated into Spanish]"
        },
        {
            "question": "Te dar\u00eda una excusa o explicaci\u00f3n para un asesinato?"
        },
        {
            "question": "\u00bfCu\u00e1l pa\u00eds tiene el nombre m\u00e1s excitante?"
        },
        {
            "question": "\u00bfQu\u00e9 haces cuando realmente te sientes dispuesto a animarte?"
        },
        {
            "question": "\u00bfCu\u00e1l fue la \u00faltima vez en la que sucedi\u00f3 algo realmente avergonzoso a ti?"
        },
        {
            "question": "\u00bfQu\u00e9 es lo inesperado m\u00e1s en tu departamento?"
        },
        {
            "question": "\u00bfQu\u00e9 fue tu \u00faltima entrega equivocada?"
        },
        {
            "question": "Por qu\u00e9 hay siempre pelos pubianos en ba\u00f1os p\u00fablicos?"
        },
        {
            "question": "\u00bfQu\u00e9 hubieses preferido m\u00e1s: Un terremoto hermoso o un tornado maduro?"
        },
        {
            "question": "\u00bfPreferir\u00edas pasar 4 horas hablando de pol\u00edtica con tres hippies barbudos o con tres nazis aburridos?"
        },
        {
            "question": "\u00bfQu\u00e9 es peor: un subastador con s\u00edndrome de Tourette o un chismosero profesional con enfermedad de Parkinson?"
        },
        {
            "question": "\u00bfQu\u00e9 olores recuerdan la infancia/la etapa escolar en tus pensamientos?"
        },
        {
            "question": "Ha habido alguna vez en que fallaste tan mal durante una consulta de salud mamaria que despu\u00e9s no hubo sexo?"
        },
        {
            "question": "\u00bfC\u00f3mo preferes secar tus manos en ba\u00f1os p\u00fablicos?"
        },
        {
            "question": "\u00bfNos destruye la iron\u00eda?"
        },
        {
            "question": "\"Hab\u00edamos de tener Go-gos antes? \""
        },
        {
            "question": "Preferes una capa gruesa o dos delgadas en invierno?"
        },
        {
            "question": "Haces tocar o aplaudir al dar tu acuerdo?"
        },
        {
            "question": "\u00bfHas estado una vez involucrado t\u00fa mismo en ello, gritando \"\u00a1Oh!\", \"\u00a1Qu\u00e9 lindo!\", \"\u00a1Mira!\", al igual que la gente? ["
        },
        {
            "question": "Cu\u00e1l sentido estar\u00eda dispuesto a sacrificarlo primero, si hubiese que elegir uno?"
        },
        {
            "question": "\u00bfCu\u00e1l es tu dinosaurio favorito?"
        },
        {
            "question": "\u00bfDespieras tus zapatos en casa inmediatamente o m\u00e1s tarde? (For formal Spanish) or \u00bfTe pones tus zapatos en casa al instante o m\u00e1s tarde? (For colloquial Spanish)"
        },
        {
            "question": "Si mi vida fuera un texto escolar, \u00bfc\u00f3mo se le llamar\u00eda?"
        },
        {
            "question": "Preferir shepar una oveja sin que alguien lo sepa, o no hacerlo en todo, pero todos creen que lo has hecho."
        }
    ];

// Function to select 5 random questions
function selectRandomQuestions(questions: { question: string }[], count: number): { question: string }[] {
  return questions.sort(() => 0.5 - Math.random()).slice(0, count);
}

// Select 5 random questions
const randomQuestions = selectRandomQuestions(questionsFromScript, 5);

export const react: Topic = {
  topic: 'React',
  totalQuestions: 5,
  questions: randomQuestions,
}